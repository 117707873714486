



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js'
import { RobotManipulator } from 'rv'

import HotspotLabel from '@/components/HotspotLabel.vue'
import { GeneratedHotspot, Hotspot, InterpolationOptions } from '@/models/Hotspot'
import { HotspotMarker, RobotMarker, ToolMarker, ThickLine } from '@/prefabs'

import Hammer from 'hammerjs'
import { PositionalAudio } from 'three'

@Component({
  components: {
    HotspotLabel
  }
})
export default class ThreeJsComponent extends Vue {
  private watchers: {[key: string]: () => void} = {}

  // THREE JS
  private canvasDiv!: HTMLElement
  private scene!: THREE.Scene
  private camera!: THREE.PerspectiveCamera
  private renderer!: THREE.WebGLRenderer
  private labelRenderer!: CSS2DRenderer
  private raycaster: THREE.Raycaster = new THREE.Raycaster()
  private environment!: THREE.Group
  private orbitControls!: OrbitControls;
  private transformControls!: TransformControls;
  private line!: THREE.Object3D
  private transformControlsTranslateSnap = 0.01
  private transformControlsRotateSnap = 6 * Math.PI / 180

  // Robot Visualization
  private robotManipulator!: RobotManipulator
  private robotMarker!: RobotMarker
  private clock!: THREE.Clock
  private animationMixer!: THREE.AnimationMixer
  private animationAction!: THREE.AnimationAction
  private toolMarker!: ToolMarker
  private framesPerRobotUpdate = 3
  private frameCounter = 0
  private divergeOnPointToPoint = true
  private deltaTime = 0
  private averageTime = 0
  private removeDebugLineTimer: number | undefined
  private removeDebugLine: (() => void) | undefined

  private transformControlsListener!: () => void

  private loading = false

  @Prop(String) readonly environmentModelPath!: string | null
  @Prop({ required: true }) hotspots!: Hotspot[]
  @Prop(Hotspot) selectedHotspot!: Hotspot | null
  @Prop(String) robotUrl!: string | undefined
  @Prop(Boolean) showRobot!: boolean
  @Prop(Boolean) refineRobot!: boolean
  @Prop(THREE.Vector3) robotPosition!: THREE.Vector3 | null
  @Prop(THREE.Quaternion) robotRotation!: THREE.Quaternion | null
  @Prop(Hotspot) refineHotspot!: Hotspot | null
  @Prop({
    default: true
  }) animateToolOrientation!: boolean

  @Prop({
    default: 'translate'
  }) transformControlsMode!: 'translate' | 'rotate'

  /**
   * Does the basic setup for this component.
   */
  private mounted (): void {
    const canvasDivResult = document.getElementById('canvasDiv')
    if (canvasDivResult == null) {
      return console.error('canvasDiv not found.')
    }
    this.canvasDiv = canvasDivResult

    // SCENE SETUP
    this.scene = new THREE.Scene()
    this.scene.background = new THREE.Color(0xE5E5E5)
    this.camera = new THREE.PerspectiveCamera(75, this.canvasDiv.clientWidth / this.canvasDiv.clientHeight, 0.1, 1000)
    this.camera.position.set(-5, 5, 5)

    // RENDERERS: 3D and 2D OVERLAY
    this.renderer = new THREE.WebGLRenderer({
      preserveDrawingBuffer: true
    })
    this.renderer.outputEncoding = THREE.sRGBEncoding
    this.labelRenderer = new CSS2DRenderer()
    this.onWindowResize() // used here to set the camera aspect ratio and canvas sizes of renderer and labelRenderer
    this.labelRenderer.domElement.style.position = 'absolute'
    this.labelRenderer.domElement.style.top = '0px'
    this.labelRenderer.domElement.style.pointerEvents = 'none'
    this.canvasDiv.appendChild(this.renderer.domElement)
    this.canvasDiv.appendChild(this.labelRenderer.domElement)

    // LIGHTING
    const light = new THREE.AmbientLight(0xffffff, 1.2) // soft white light
    const robotLight = new THREE.DirectionalLight(0xffffff, 0.6)
    robotLight.castShadow = true
    robotLight.shadow.mapSize.setScalar(1024)
    robotLight.position.set(5, 30, 5)
    this.scene.add(light)
    this.scene.add(robotLight)

    // EVENT LISTENERS
    window.addEventListener('resize', this.onWindowResize, false)
    const hammer = new Hammer(this.renderer.domElement)
    hammer.on('doubletap', (e) => {
      if (e instanceof TouchEvent) {
        return console.warn('Currently unable to handle TouchEvents.')
      }
      this.onMouseClick(e.srcEvent as PointerEvent)
    })

    // THREEJS CONTROLS
    this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement)
    this.createTransformControls(this.renderer.domElement)

    this.createRobotMarker()

    // ANIMATIONS
    this.clock = new THREE.Clock()
    this.toolMarker = new ToolMarker()
    this.toolMarker.visible = false
    this.scene.add(this.toolMarker)

    // START RENDERING
    this.animate()
  }

  /**
   * Recursive rendering loop function of three.js.
   * Renders the 3D scene and the 2D overlay containing annotations like the hotspots.
   */
  private animate (): void {
    const delta = this.clock.getDelta()
    if (this.animationMixer) {
      this.animationMixer.update(delta)
      if (this.animationAction.isRunning() && this.frameCounter % this.framesPerRobotUpdate === 0) {
        this.deltaTime = this.robotManipulator.deltaTime
        this.averageTime = this.robotManipulator.averageTime
        if (this.animateToolOrientation) {
          this.robotManipulator.setEndeffector(undefined, this.toolMarker.position.toArray(), this.toolMarker.quaternion.toArray())
        } else {
          this.robotManipulator.setEndeffector(undefined, this.toolMarker.position.toArray())
        }
      }
      this.frameCounter++
    }
    requestAnimationFrame(this.animate)
    this.renderer.render(this.scene, this.camera)
    this.labelRenderer.render(this.scene, this.camera)
  }

  private updateAnimation (): void {
    const secondsPerHotspot = 5
    const { values, times } = this.hotspots.reduce((acc: {values: number[], times: number[]}, hotspot: GeneratedHotspot, index: number) => {
      if (hotspot instanceof Hotspot && hotspot.approachMode === 'FollowSurface' && hotspot.interpolationOptions.vectors) {
        acc = hotspot.interpolationOptions.vectors?.reduce((genAcc: {values: number[], times: number[]}, genHotspot: GeneratedHotspot, genIndex: number) => {
          genAcc.values.push(genHotspot.position.x, genHotspot.position.y, genHotspot.position.z)
          genAcc.times.push((index - 1) * secondsPerHotspot + genIndex * secondsPerHotspot / (hotspot.interpolationOptions.vectors!.length + 1))
          return genAcc
        }, acc)
      } else if (this.divergeOnPointToPoint && hotspot instanceof Hotspot && hotspot.approachMode === 'PointToPoint' && index > 0) {
        const midpoint = this.hotspots[index - 1].position.clone().lerp(hotspot.position, 0.5)
        const offset = this.hotspots[index - 1].normal.lerp(hotspot.normal, 0.5).multiplyScalar(0.15)
        midpoint.add(offset)
        acc.values.push(midpoint.x, midpoint.y, midpoint.z)
        acc.times.push((index - 0.5) * secondsPerHotspot)
      }
      acc.values.push(hotspot.position.x, hotspot.position.y, hotspot.position.z)
      acc.times.push(index * secondsPerHotspot)
      return acc
    }, { values: [], times: [] })
    values.push(values[0], values[1], values[2])
    values.push(values[0], values[1], values[2])
    times.push((this.hotspots.length - 1) * secondsPerHotspot + 1)
    times.push((this.hotspots.length - 1) * secondsPerHotspot + 2)

    const positionTrack = new THREE.VectorKeyframeTrack('.position', times, values, THREE.InterpolateLinear)

    const { quaternions, qTimes } = this.hotspots.reduce((acc: {quaternions: number[], qTimes: number[]}, hotspot: Hotspot, index: number) => {
      const opposite = hotspot.quaternion.clone().multiply(new THREE.Quaternion(0, 1, 0, 0))
      acc.quaternions.push(opposite.x, opposite.y, opposite.z, opposite.w)
      acc.qTimes.push(index * secondsPerHotspot)
      return acc
    }, { quaternions: [], qTimes: [] })
    quaternions.push(quaternions[0], quaternions[1], quaternions[2], quaternions[3])
    quaternions.push(quaternions[0], quaternions[1], quaternions[2], quaternions[3])
    qTimes.push((this.hotspots.length - 1) * secondsPerHotspot + 1)
    qTimes.push((this.hotspots.length - 1) * secondsPerHotspot + 2)

    const orientationTrack = new THREE.QuaternionKeyframeTrack('.quaternion', qTimes, quaternions, THREE.InterpolateLinear)

    const { aValues, aTimes } = this.hotspots.reduce((acc: {aValues: number[], aTimes: number[]}, hotspot: Hotspot, index: number) => {
      const type = hotspot.action?.Type ?? 'detach'
      const colorMap = {
        attach: [0xFC / 0xFF, 0xBD / 0xFF, 0x0C / 0xFF],
        detach: [0x4F / 0xFF, 0xB5 / 0xFF, 0x8C / 0xFF]
      }
      acc.aValues.push(...colorMap[type])
      acc.aTimes.push(index * secondsPerHotspot)
      return acc
    }, { aValues: [], aTimes: [] })
    aValues.push(aValues[0] || 1, aValues[1] || 0.3, aValues[2])
    aValues.push(aValues[0] || 1, aValues[1] || 0.3, aValues[2])
    aTimes.push((this.hotspots.length - 1) * secondsPerHotspot + 1)
    aTimes.push((this.hotspots.length - 1) * secondsPerHotspot + 2)

    const colorTrack = new THREE.ColorKeyframeTrack('.material.color', aTimes, aValues, THREE.InterpolateDiscrete)

    // -1 for duration causes duration this to be set automatically
    const clip = new THREE.AnimationClip('move-along-hotspots', -1, [positionTrack, orientationTrack, colorTrack])
    this.animationMixer = new THREE.AnimationMixer(this.toolMarker)
    this.animationAction = this.animationMixer.clipAction(clip)
  }

  /**
   * Creates the object that indicates where the robot will be located.
   * Also adds it to the scene if robot position and normal are set.
   */
  private createRobotMarker () {
    this.robotMarker = new RobotMarker(this.robotPosition, this.robotRotation, () => this.$emit('robot-click'))
    this.scene.add(this.robotMarker)
  }

  /**
   * Creates the three.js transform controls used when refining a hotspot.
   */
  private createTransformControls (canvas: HTMLCanvasElement): void {
    const transformControls = new TransformControls(this.camera, canvas)
    transformControls.addEventListener(
      'mouseDown',
      () => (this.orbitControls.enabled = false)
    )
    transformControls.addEventListener(
      'mouseUp',
      () => (this.orbitControls.enabled = true)
    )
    transformControls.setTranslationSnap(this.transformControlsTranslateSnap)
    transformControls.setRotationSnap(this.transformControlsRotateSnap)
    transformControls.setSize(0.5)
    transformControls.enabled = false
    this.transformControls = transformControls
  }

  /**
   * Removes the previous environment model and adds the new model into the scene.
   * Hotspots and other labels are not removed.
   */
  @Watch('environmentModelPath')
  onModelPathChanged (path: string | null): void {
    if (path === null) return
    this.loading = true
    const loader = new GLTFLoader()
    loader.load(path, (gltf) => {
      this.scene.remove(this.environment)
      this.scene.add(gltf.scene)
      this.environment = gltf.scene
      this.environment.updateMatrixWorld()
      this.updateHotspotLine(this.hotspots)
      this.setCameraFocus(this.environment.position, 7)
      this.loading = false
      setTimeout(() => {
        const image = this.getSceneImage()
        this.$emit('image', image)
      }, 2000)
    }, undefined, (error) => {
      console.error(error)
    })
  }

  /**
   * Updates the threejs scene when hotspots are inserted or removed into the array.
   * For simplicity this currently recreates all labels whenever the array changes.
   */
  @Watch('hotspots')
  onHotspotsChange (hotspots: Hotspot[], oldHotspots: Hotspot[]): void {
    this.removeHotspotWatchers()
    oldHotspots.forEach(this.removeMarkerForHotspot)
    hotspots.forEach(this.createMarkerForHotspot)

    this.addHotspotWatchers()

    this.updateHotspotLine(hotspots)
  }

  @Watch('hotspots', { deep: true })
  onHotspotsChangeDeep (hotspots: Hotspot[], oldHotspots: Hotspot[]): void {
    if (this.environment) {
      const removedHotspots = oldHotspots.filter((h: Hotspot) => hotspots.indexOf(h) === -1)
      removedHotspots.forEach(this.removeApproachLine)
      const addedHotspots = hotspots.filter((h: Hotspot) => oldHotspots.indexOf(h) === -1)
      if (removedHotspots.length === 0 && addedHotspots.length === 0) {
        this.updateHotspotLine(hotspots)
      } else {
        this.updateHotspotLine(addedHotspots)
      }
    }
  }

  /**
   * Sets the focus of the camera when a hotspot is selected or deselected.
   */
  @Watch('selectedHotspot')
  focusCameraOnHotspot (hotspot: Hotspot | null, focusDistance?: number): void {
    let target: THREE.Vector3
    if (hotspot !== null) {
      target = hotspot.position.clone()
      if (!focusDistance || typeof focusDistance !== 'number') focusDistance = 2

      this.setCameraFocus(target, focusDistance)
    }
  }

  /**
   * Tries loading a robot from the URDF file provided as a URL
   */
  @Watch('robotUrl')
  requestUrdfFile (url: string): void {
    if (this.robotManipulator) this.scene.remove(this.robotManipulator.robot)
    if (url) {
      console.log(`Starting to load URDF from ${url}...`)
      const solverOptions = {}
      const robotMaterial = new THREE.MeshPhysicalMaterial({
        color: 0x848789,
        roughness: 0.5,
        metalness: 0.25,
        opacity: 0.7
      })
      this.robotManipulator = new RobotManipulator(this.scene, this.camera, this.renderer.domElement, this.orbitControls, url, 'view', undefined, undefined, undefined, false, () => {
        this.robotManipulator.setPosition(this.robotPosition?.toArray())
        const manipulatorRotationCorrection = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), -90 * THREE.MathUtils.DEG2RAD)
        this.robotManipulator.setRotation(new THREE.Euler().setFromQuaternion(this.robotRotation!.clone().multiply(manipulatorRotationCorrection)).toArray())
        this.robotManipulator.setEndeffector(undefined, this.hotspots[0]?.position?.toArray(), this.hotspots[0]?.quaternion?.toArray())
        this.updateRobotVisible(this.showRobot)
        console.log(`URDF loaded from ${url}.`)
      }, true, solverOptions, false, robotMaterial)
    }
  }

  /**
   * Updates the visibility of the robot if it is available.
   */
  @Watch('showRobot')
  updateRobotVisible (showRobot: boolean): void {
    this.updateAnimation()
    if (this.robotManipulator && this.robotManipulator.robot) {
      this.robotManipulator.robot.visible = showRobot
      this.toolMarker.visible = showRobot
      this.robotMarker.visible = !showRobot
      this.robotMarker.setLabelVisible(!showRobot)
      if (showRobot) {
        this.$gtag.event('startPreview', {
          event_category: 'Task',
          value: Math.round(Date.now() / 1000),
          timestamp: Math.round(Date.now() / 1000)
        })
        this.robotManipulator.solve = true
        this.animationAction.play()
      } else {
        this.$gtag.event('endPreview', {
          event_category: 'Task',
          value: Math.round(Date.now() / 1000),
          timestamp: Math.round(Date.now() / 1000)
        })
        this.robotManipulator.solve = false
        this.animationAction.stop()
        this.animationAction.reset()
      }
    }
  }

  /**
   * Updates the position of the robot and the robot indicator.
   */
  @Watch('robotPosition', { deep: true })
  updateRobotPosition (newPosition: THREE.Vector3 | null): void {
    if (newPosition) {
      if (this.robotManipulator) this.robotManipulator.setPosition(newPosition.toArray())
      if (this.robotMarker) this.robotMarker.position.copy(newPosition)
    }
    if (this.robotMarker) {
      const visible = this.robotPosition !== null && this.robotRotation !== null && !this.showRobot
      this.robotMarker.visible = visible
      this.robotMarker.setLabelVisible(visible)
    }
  }

  @Watch('robotRotation', { deep: true })
  updateRobotRotation (newRotation: THREE.Quaternion | null): void {
    if (newRotation) {
      if (this.robotManipulator) {
        const manipulatorRotationCorrection = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), -90 * THREE.MathUtils.DEG2RAD)
        this.robotManipulator.setRotation(new THREE.Euler().setFromQuaternion(this.robotRotation!.clone().multiply(manipulatorRotationCorrection)).toArray())
      }
      if (this.robotMarker) this.robotMarker.quaternion.copy(newRotation)
    }
    if (this.robotMarker) {
      const visible = this.robotPosition !== null && this.robotRotation !== null && !this.showRobot
      this.robotMarker.visible = visible
      this.robotMarker.setLabelVisible(visible)
    }
  }

  @Watch('refineRobot')
  onRefineRobotChange (newVal: boolean): void {
    if (newVal) {
      this.$gtag.event('startRefineRobot', {
        event_category: 'Task',
        value: Math.round(Date.now() / 1000),
        timestamp: Math.round(Date.now() / 1000)
      })
      this.transformControlsListener = () => {
        const target = new THREE.Vector3()
        this.robotMarker.getWorldPosition(target)
        if (this.robotPosition === null) this.robotPosition = target
        else this.robotPosition.copy(target)

        if (this.robotRotation === null) this.robotRotation = new THREE.Quaternion()
        this.robotRotation.copy(this.robotMarker.quaternion)
      }
      this.transformControls.addEventListener('change', this.transformControlsListener)
      this.transformControls.attach(this.robotMarker)
      this.transformControls.enabled = true
      this.setCameraFocus(this.robotMarker.position)
      this.scene.add(this.transformControls)
    } else {
      this.$gtag.event('stopRefineRobot', {
        event_category: 'Task',
        value: Math.round(Date.now() / 1000),
        timestamp: Math.round(Date.now() / 1000)
      })
      this.transformControls.removeEventListener('change', this.transformControlsListener)
      this.transformControls.detach()
      this.transformControls.enabled = false
      this.scene.remove(this.transformControls)
    }
  }

  /**
   * Determines wether to start or stop the Refine Hotspot mode and does accordingly.
   */
  @Watch('refineHotspot')
  onRefineHotspotChange (newVal: Hotspot | null, oldVal: Hotspot | null): void {
    if (newVal !== null) {
      this.$gtag.event('startRefine', {
        event_category: 'Hotspot',
        event_label: newVal.id,
        value: Math.round(Date.now() / 1000),
        timestamp: Math.round(Date.now() / 1000)
      })
      this.startHotspotRefinement(newVal)
    } else if (oldVal !== null) {
      this.$gtag.event('stopRefine', {
        event_category: 'Hotspot',
        event_label: oldVal.id,
        value: Math.round(Date.now() / 1000),
        timestamp: Math.round(Date.now() / 1000)
      })
      this.stopHotspotRefinement(oldVal)
    }
  }

  @Watch('transformControlsMode')
  onTransformControlsModeChange (mode: 'translate' | 'rotate'): void {
    if (!this.refineRobot && this.refineHotspot === null) return
    if (mode === 'rotate') {
      this.transformControls.setMode('rotate')
      this.transformControls.setSpace('local')
    } else {
      this.transformControls.setMode('translate')
      this.transformControls.setSpace('world')
    }
  }

  /**
   * Switches into the Refine Hotspot mode for the given hotspot.
   */
  private startHotspotRefinement (hotspot: Hotspot): void {
    const marker = this.scene.getObjectByName(hotspot.id)
    if (!marker || !(marker instanceof HotspotMarker)) return console.warn(`Tried updating marker that does not exist: ${hotspot.id}`)
    // marker.visible = false
    marker.matrixAutoUpdate = true
    marker.setOpacity(1)

    this.transformControlsListener = () => {
      const target = new THREE.Vector3()
      marker.getWorldPosition(target)
      hotspot.position.copy(target)
      hotspot.quaternion.copy(marker.quaternion)
    }

    this.transformControls.attach(marker)
    this.transformControls.enabled = true
    this.scene.add(this.transformControls)

    this.transformControls.addEventListener('change', this.transformControlsListener)

    this.focusCameraOnHotspot(hotspot, 1)
  }

  /**
   * Exits the Refine Hotspot mode for the given hotspot.
   */
  private stopHotspotRefinement (hotspot: Hotspot): void {
    const marker = this.scene.getObjectByName(hotspot.id)
    if (!marker || !(marker instanceof HotspotMarker)) return console.warn(`Tried hiding marker that does not exist: ${hotspot.id}`)

    marker.matrixAutoUpdate = false

    this.transformControls.enabled = false
    this.scene.remove(this.transformControls)

    // update Hotspot model based on Threejs marker
    hotspot.position.copy(marker.position)
    delete hotspot.interpolationOptions.vectors
    marker.getWorldQuaternion(hotspot.quaternion)

    marker.setOpacity(0.5)

    this.transformControls.removeEventListener('change', this.transformControlsListener)

    this.focusCameraOnHotspot(hotspot, 3)
  }

  /**
   * Adds hotspot watchers for each of the current hotspots.
   * Can be used when all hotspots in the array are new.
   */
  private addHotspotWatchers (): void {
    if (!this.hotspots) return
    this.hotspots.forEach(this.addHotspotWatcher)
  }

  /**
   * Removes all hotspot watchers.
   */
  private removeHotspotWatchers (): void {
    if (!this.watchers) return
    Object.keys(this.watchers).filter((key: string) => {
      return typeof this.watchers[key] === 'function'
    }).forEach((key: string) => {
      this.watchers[key]()
    })
  }

  private getSceneImage (): string {
    return this.renderer.domElement.toDataURL('image/png')
  }

  /**
   * Adds a watcher to the given hotspots position that updates the label coordinates accordingly.
   *
   * Watchers for hotspot.position and .normal update the Markers position in the 3D scene.
   * Watchers for hotspto.approachMode and .interpolationOptions delete the interpolations cache and update the path visualization.
   */
  private addHotspotWatcher (hotspot: Hotspot): void {
    if (!this.hotspots) return
    const index = this.hotspots.indexOf(hotspot)
    if (index === -1) return console.error('Unable to add watcher for inactive hotspot.')

    this.watchers[hotspot.id + '-position'] = this.$watch(`hotspots.${index}.position`, (newPosition: THREE.Vector3) => {
      if (!newPosition) return
      this.updateMarker(hotspot.id, newPosition)
      this.updateHotspotLine(hotspot)
    }, { deep: true })

    this.watchers[hotspot.id + '-quaternion'] = this.$watch(`hotspots.${index}.quaternion`, (newQuaternion: THREE.Quaternion) => {
      if (!newQuaternion) return
      this.updateMarker(hotspot.id, undefined, newQuaternion)
      this.updateHotspotLine(hotspot)
    }, { deep: true })

    this.watchers[hotspot.id + '-approachMode'] = this.$watch(`hotspots.${index}.approachMode`, () => {
      delete hotspot.interpolationOptions?.vectors
      this.updateHotspotLine(hotspot)
      this.$gtag.event('editApproachMode', {
        event_category: hotspot.approachMode,
        event_label: hotspot.id,
        value: Math.round(Date.now() / 1000),
        timestamp: Math.round(Date.now() / 1000)
      })
    }, { deep: true })

    this.watchers[hotspot.id + '-interpolationOptions'] = this.$watch(`hotspots.${index}.interpolationOptions`, () => {
      if (!hotspot.interpolationOptions || hotspot.approachMode !== 'FollowSurface') return
      delete hotspot.interpolationOptions?.vectors
      this.updateHotspotLine(hotspot)
    }, { deep: true })
  }

  /**
   * Sets the position and rotation for the marker with the given name.
   */
  private updateMarker (name: string, position?: THREE.Vector3, quaternion?: THREE.Quaternion): void {
    const marker: HotspotMarker = this.scene.getObjectByName(name) as HotspotMarker
    if (marker !== null && typeof marker !== 'undefined') {
      if (position) marker.position.copy(position)
      if (quaternion) marker.rotation.setFromQuaternion(quaternion)
    }
  }

  /**
   * Updates the line visualizing the current robot path.
   */
  private updateHotspotLine (hotspots: Hotspot[] | Hotspot): void {
    if (hotspots instanceof Hotspot) hotspots = [hotspots]
    if (this.line) {
      hotspots.forEach(this.removeApproachLine)
    } else {
      this.line = new THREE.Group()
      this.scene.add(this.line)
    }

    hotspots.forEach((hotspot: Hotspot): void => {
      const result: THREE.Object3D | null = this.createApproachLine(hotspot)
      if (result !== null) this.line.add(result)
    })
  }

  private removeApproachLine (hotspot: Hotspot): void {
    const hLine = this.line.getObjectByName(`${hotspot.id}-approach-line`)
    if (hLine) {
      this.line.remove(hLine)
      hLine.traverse((obj: THREE.Object3D) => {
        if (obj instanceof LineMaterial ||
          obj instanceof LineGeometry
        ) obj.dispose()
      })
    }
  }

  /**
   * Draws the path visualization for the given hotspot from it's predecessor to the given hotspot.
   */
  private createApproachLine (hotspot: Hotspot): THREE.Object3D | null {
    if (!this.hotspots) return null
    const colorMap = {
      detach: 0x4FB58C,
      attach: 0xFCBD0C
    }
    const index = this.hotspots.indexOf(hotspot)
    if (index === 0) return null
    const prev: Hotspot = this.hotspots[index - 1]
    const color = colorMap[prev.action?.Type ?? 'detach']
    if (hotspot.approachMode !== 'FollowSurface' || typeof hotspot.interpolationOptions === 'undefined') {
      const line = new ThickLine(this.canvasDiv, [prev.position, hotspot.position], 3, color, hotspot.approachMode === 'PointToPoint')
      line.name = `${hotspot.id}-approach-line`
      return line
    }

    if (!hotspot.interpolationOptions.vectors) {
      hotspot.interpolationOptions.vectors = this.interpolatePath(prev, hotspot, hotspot.interpolationOptions)
    }

    const linePoints: THREE.Vector3[] = hotspot.interpolationOptions.vectors?.map((gn: GeneratedHotspot): THREE.Vector3 => {
      return gn.position
    }) || []

    linePoints.unshift(prev.position)
    linePoints.push(hotspot.position)

    const line = new ThickLine(this.canvasDiv, linePoints, 3, color)
    line.name = `${hotspot.id}-approach-line`
    return line
  }

  /**
   * Sets the cameras focus onto the given position and zooms in to the given focusDistance.
   */
  private setCameraFocus (target: THREE.Vector3 | THREE.Object3D, focusDistance?: number): void {
    this.orbitControls.target = (target instanceof THREE.Object3D) ? target.position.clone() : target.clone()
    if (focusDistance) {
      this.camera.translateZ(-1 * (this.camera.position.distanceTo(this.orbitControls.target) - focusDistance))
    }
    this.orbitControls.update()
  }

  /**
   * Interpolates the path between 2 points on a mesh using raycasts.
   */
  public interpolatePath (start: GeneratedHotspot, end: GeneratedHotspot, options: Partial<InterpolationOptions> = {}): GeneratedHotspot[] {
    const defaultOptions: InterpolationOptions = {
      intermediatePoints: Math.round(start.position.distanceTo(end.position) / 0.1),
      clearance: 0.001,
      castDistance: 0.3
    }
    const options2: InterpolationOptions = { ...defaultOptions, ...options }

    const interpolationNormal: THREE.Vector3 = start.normal.clone().lerp(end.normal, 0.5).normalize()
    interpolationNormal.multiplyScalar(options2.castDistance)
    const interpolationStart: THREE.Vector3 = start.position.clone().add(interpolationNormal)
    const interpolationStepVector: THREE.Vector3 = end.position.clone().sub(start.position).divideScalar(options2.intermediatePoints + 1)

    const origins: THREE.Vector3[] = []
    const currentRaycastOrigin: THREE.Vector3 = interpolationStart.clone().add(interpolationStepVector)
    // We multiply by -1 because we want to cast back towards the mesh and the original normal vector points away from it.
    const raycastDirection = interpolationNormal.multiplyScalar(-1)

    const interpolatedPath: THREE.Intersection[] = []
    // For loop instead of .map() needed because not every raycast is guaranteed to hit the mesh and return a valid point.
    for (let i = 0; i < options2.intermediatePoints; i++) {
      origins.push(currentRaycastOrigin.clone())

      this.raycaster.set(currentRaycastOrigin, raycastDirection)
      const hits = this.raycaster.intersectObject(this.environment, true)

      if (hits.length > 0) {
        const hit = hits[0]
        interpolatedPath.push(hit)
      }

      currentRaycastOrigin.add(interpolationStepVector)
    }

    const interpolationDebugLine = new ThickLine(this.canvasDiv, origins.reduce((prev: THREE.Vector3[], origin: THREE.Vector3): THREE.Vector3[] => {
      prev.push(origin)
      prev.push(origin.clone().add(interpolationNormal))
      prev.push(origin)
      return prev
    }, []), 5, 0x0000ff)
    if (this.removeDebugLineTimer && this.removeDebugLine) {
      this.removeDebugLine()
      clearTimeout(this.removeDebugLineTimer)
    }
    this.scene.add(interpolationDebugLine)
    this.removeDebugLine = () => {
      this.scene.remove(interpolationDebugLine)
      interpolationDebugLine.dispose()
    }
    this.removeDebugLineTimer = setTimeout(this.removeDebugLine, 3000)

    const intermediateHotspots: GeneratedHotspot[] = interpolatedPath.map((intersection: THREE.Intersection) => {
      const normal: THREE.Vector3 = intersection.face?.normal || new THREE.Vector3(0, 1, 0)
      const position: THREE.Vector3 = intersection.point.add(normal.multiplyScalar(options2.clearance))
      const id = Math.random().toString(36).substring(7)

      const obj = new THREE.Group()
      obj.position.copy(position)
      obj.lookAt(normal.add(position))
      const rotation: THREE.Euler = obj.rotation

      return new GeneratedHotspot(id, position, rotation)
    })
    return intermediateHotspots
  }

  /**
   * Creates a new marker for the given hotspot and adds it to the scene.
   */
  private createMarkerForHotspot (hotspot: Hotspot, index: number): void {
    const getUrl = window.location
    const baseUrl = getUrl.protocol + '//' + getUrl.host + '/'
    const toolUrl = baseUrl + 'urdf-model/stl_files/E_Schunk.STL'

    const label = new HotspotMarker(hotspot, index, (hotspot) => this.$emit('hotspot-click', hotspot), 0.5, toolUrl)
    this.scene.add(label)
  }

  /**
   * Removes the marker for the given hotspot from the scene.
   */
  private removeMarkerForHotspot (hotspot: Hotspot): void {
    const marker = this.scene.getObjectByName(hotspot.id)
    if (!marker || !(marker instanceof HotspotMarker)) return console.warn(`Tried removing marker that does not exist: ${hotspot.id}`)
    this.scene.remove(marker)
    marker.dispose()
  }

  /**
   * Used with an eventListener on the threejs renderer's domElement to emit a 3d-click event when the user double clicks on the model.
   */
  private onMouseClick (event: MouseEvent | PointerEvent): void {
    const intersection = this.getIntersectionFromMouseEvent(event)
    const position = intersection?.point

    if (position == null) {
      return console.warn('You clicked outside of the model. We can\'t add a hotspot there.')
    }

    const normal = intersection?.face?.normal
    let normalVector: THREE.Vector3 | null = null
    let rotation: THREE.Euler | null = null
    if (normal) {
      const obj = new THREE.Group()
      obj.position.copy(position)
      obj.lookAt(normal.clone().add(position))

      normalVector = normal
      rotation = obj.rotation
    }

    this.$emit('3d-click', position, rotation, normalVector)
  }

  /**
   * Finds the intersection on the mesh belonging to a given MouseEvent.
   */
  private getIntersectionFromMouseEvent (event: MouseEvent | PointerEvent): THREE.Intersection | null {
    const coordinates = new THREE.Vector2()
    const rendererSize = new THREE.Vector2()
    this.renderer.getSize(rendererSize)
    // Coordinates go from -1 to 1
    coordinates.x = (event.offsetX / (rendererSize.x)) * 2 - 1
    coordinates.y = -(event.offsetY / rendererSize.y) * 2 + 1
    return this.getIntersectionFromCoords(coordinates)
  }

  /**
   * Tries to find an intersetion with the environment mesh based on coordinates in screen space.
   */
  private getIntersectionFromCoords (coords: THREE.Vector2): THREE.Intersection | null {
    this.raycaster.setFromCamera(coords, this.camera)
    const hits = this.raycaster.intersectObject(this.environment, true)

    if (hits.length === 0) {
      return null
    }

    const hit = hits[0]
    return hit
  }

  /**
   * Handles resizing of the threejs camera, renderer and labelRenderer.
   */
  private onWindowResize (): void {
    this.camera.aspect = this.canvasDiv.clientWidth / this.canvasDiv.clientHeight
    this.camera.updateProjectionMatrix()

    const width = this.canvasDiv.clientWidth
    const height = this.canvasDiv.clientHeight

    this.renderer.setSize(width, height)
    this.labelRenderer.setSize(width, height)
  }
}

