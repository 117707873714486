









import { Component, Vue } from 'vue-property-decorator'
import CountryFlag from 'vue-country-flag'
import i18n from '@/plugins/i18n'

@Component({
  components: {
    CountryFlag
  }
})
export default class LanguageSwitch extends Vue {
  private languages = [
    { flag: 'us', language: 'en', title: 'English' },
    { flag: 'de', language: 'de', title: 'Deutsch' }
  ]

  private mounted () {
    let locale: string | null = null
    if (this.$route.query.locale !== null && this.$route.query.locale instanceof Array) {
      locale = this.$route.query.locale[0]
    } else {
      locale = this.$route.query.locale
    }
    const locales: (string | null)[] = ['de', 'en']
    if (locales.indexOf(locale) !== -1 && locale !== null) {
      this.changeLocale(locale)
    }
  }

  private notSelected = this.languages[(i18n.locale === 'en') ? 1 : 0]

  private changeLocale (newLocale: string): void {
    i18n.locale = newLocale
    this.notSelected = this.languages[(i18n.locale === 'en') ? 1 : 0]
  }
}
