



































































































































































































import * as THREE from 'three'
import { Component, Prop, Vue } from 'vue-property-decorator'

import HotspotLabel from '@/components/HotspotLabel.vue'
import NumberInput from '@/components/NumberInput.vue'
import { Hotspot } from '@/models/Hotspot'

@Component({
  components: {
    HotspotLabel,
    NumberInput
  }
})
export default class EditHotspotMenu extends Vue {
  @Prop() private hotspot!: Hotspot
  @Prop() private removeHotspot!: (h: Hotspot) => void
  @Prop() private toggleHotspot!: (h: Hotspot) => void
  @Prop() private setRefineHotspot!: (h: Hotspot | null) => void
  @Prop() readonly refineHotspot!: Hotspot | null
  @Prop() readonly doneSteps!: boolean[]
  @Prop(Number) private currentIndex!: number
  @Prop() private completeStep!: () => void
  @Prop() private setTransformControlsMode!: (val: 'translate' | 'rotate') => void
  @Prop() private transformControlsMode!: 'translate' | 'rotate'

  private approachModes = [{
    text: 'Point to Point',
    value: 'PointToPoint'
  }, {
    text: 'Linear',
    value: 'Linear'
  }, {
    text: 'Follow Surface',
    value: 'FollowSurface'
  }, {
    text: 'Spline',
    value: 'Spline',
    disabled: true
  }
  ]

  private actions = [
    {
      text: 'Deactivate',
      value: {
        Type: 'detach'
      },
      color: '#4FB58C'
    }, {
      text: 'Activate',
      value: {
        Type: 'attach'
      },
      color: '#FCBD0C'
    }]

  private approachModeIcons = {
    PointToPoint: 'mdi-redo',
    Linear: 'mdi-ray-start-arrow',
    FollowSurface: 'mdi-slope-downhill',
    Spline: 'transit-connection-variant'
  }

  private changeControlMode (): void {
    if (this.transformControlsMode === 'rotate') {
      this.setTransformControlsMode('translate')
    } else {
      this.setTransformControlsMode('rotate')
    }
  }

  private refineHotspotButton (): void {
    if (this.refineHotspot == null) {
      this.setRefineHotspot(this.hotspot)
    } else {
      this.setRefineHotspot(null)
    }
  }

  get hotspotColor (): 'accent' | 'warning' {
    return this.hotspot.action?.Type === 'attach' ? 'warning' : 'accent'
  }

  set intermediatePoints (newIntermediatePoints: string) {
    this.$set(this.hotspot.interpolationOptions, 'intermediatePoints', 2)
    this.hotspot.interpolationOptions!.intermediatePoints = parseInt(newIntermediatePoints)
    this.$gtag.event('editIntermediatePoints', {
      event_category: 'Hotspot',
      event_label: this.hotspot.id,
      value: parseInt(newIntermediatePoints)
    })
  }

  get intermediatePoints (): string {
    return this.hotspot.interpolationOptions!.intermediatePoints.toString()
  }

  set castDistance (newCastDistance: string) {
    this.hotspot.interpolationOptions!.castDistance = parseFloat(newCastDistance)
    this.$gtag.event('editCastDistance', {
      event_category: 'Hotspot',
      event_label: this.hotspot.id,
      value: parseInt(newCastDistance)
    })
  }

  get castDistance (): string {
    return this.hotspot.interpolationOptions!.castDistance.toString()
  }

  set clearance (newClearance: string) {
    this.hotspot.interpolationOptions!.clearance = parseFloat(newClearance)
  }

  get clearance (): string {
    return this.hotspot.interpolationOptions!.clearance.toString()
  }

  set rotationX (x: string) {
    const xRad = parseFloat(x) * Math.PI / 180
    if (isNaN(xRad)) return
    this.hotspot.rotation = new THREE.Euler(xRad, this.hotspot.rotation.y, this.hotspot.rotation.z)
    this.$gtag.event('editRotationX', {
      event_category: 'Hotspot',
      event_label: this.hotspot.id,
      value: xRad
    })
  }

  get rotationX (): string {
    const xDeg = this.hotspot.rotation.x / Math.PI * 180
    return xDeg.toString()
  }

  set rotationY (y: string) {
    const yRad = parseFloat(y) * Math.PI / 180
    if (isNaN(yRad)) return
    this.hotspot.rotation = new THREE.Euler(this.hotspot.rotation.x, yRad, this.hotspot.rotation.z)
    this.$gtag.event('editRotationY', {
      event_category: 'Hotspot',
      event_label: this.hotspot.id,
      value: yRad
    })
  }

  get rotationY (): string {
    const yDeg = this.hotspot.rotation.y / Math.PI * 180
    return yDeg.toString()
  }

  set rotationZ (z: string) {
    const zRad = parseFloat(z) * Math.PI / 180
    if (isNaN(zRad)) return
    this.hotspot.rotation = new THREE.Euler(this.hotspot.rotation.x, this.hotspot.rotation.y, zRad)
    this.$gtag.event('editRotationZ', {
      event_category: 'Hotspot',
      event_label: this.hotspot.id,
      value: zRad
    })
  }

  get rotationZ (): string {
    const zDeg = this.hotspot.rotation.z / Math.PI * 180
    return zDeg.toString()
  }

  set positionX (x: string) {
    const xPos = parseFloat(x)
    if (isNaN(xPos)) return
    this.hotspot.position.setX(xPos)
    this.$gtag.event('editPositionX', {
      event_category: 'Hotspot',
      event_label: this.hotspot.id,
      value: xPos
    })
  }

  get positionX (): string {
    return this.hotspot.position.x.toString()
  }

  set positionY (y: string) {
    const yPos = parseFloat(y)
    if (isNaN(yPos)) return
    this.hotspot.position.setY(yPos)
    this.$gtag.event('editPositionY', {
      event_category: 'Hotspot',
      event_label: this.hotspot.id,
      value: yPos
    })
  }

  get positionY (): string {
    return this.hotspot.position.y.toString()
  }

  set positionZ (z: string) {
    const zPos = parseFloat(z)
    if (isNaN(zPos)) return
    this.hotspot.position.setZ(zPos)
    this.$gtag.event('editPositionZ', {
      event_category: 'Hotspot',
      event_label: this.hotspot.id,
      value: zPos
    })
  }

  get positionZ (): string {
    return this.hotspot.position.z.toString()
  }
}
