

















































import { Component, Prop, Vue } from 'vue-property-decorator'

import HotspotLabel from '@/components/HotspotLabel.vue'
import { Hotspot } from '@/models/Hotspot'

@Component({
  components: {
    HotspotLabel
  }
})
export default class TimelineHotspot extends Vue {
  @Prop({
    required: true
  }) private toggleHotspotByIndex!: (index: number) => void

  @Prop({
    required: true
  }) private openHotspotDialogForIndex!: (index: number) => void

  @Prop({
    required: true
  }) private hotspot!: Hotspot

  @Prop({
    required: true
  }) private index!: number

  @Prop({
    default: false
  }) private last!: boolean

  get color (): 'accent' | 'warning' {
    if (this.hotspot.action?.Type === 'attach') return 'warning'
    return 'accent'
  }

  get textColor (): 'accent' | 'warning' | 'white' {
    if (!this.hotspot.selected) return 'white'
    if (this.hotspot.action?.Type === 'attach') return 'warning'
    return 'accent'
  }

  private show = true
  private showAddButtons: 'left' | 'right' | null = null

  private onHover (e: MouseEvent) {
    const rect = this.$el.getBoundingClientRect()
    const x = e.clientX - rect.left - (rect.width / 2)
    this.showAddButtons = (x > 0) ? 'right' : 'left'
  }

  private isTouchDevice (): boolean {
    return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0))
  }
}
