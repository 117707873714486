export enum TaskType {
  MaterialsHandling,
  MachineTending,
  QualityAssurance,
  Dispensing,
  Finishing,
  MaterialRemoval,
  Assembly,
  Welding
}

export class Task {
  name: string;
  previewImgUrl: string;
  edited: Date;
  id: string;
  type: TaskType;

  constructor (id: string, name: string, previewImgUrl: string, edited: Date, type = TaskType.MaterialsHandling) {
    this.id = id
    this.name = name
    this.previewImgUrl = previewImgUrl
    this.edited = edited
    this.type = type
  }
}
