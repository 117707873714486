import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import * as THREE from 'three'

import { UploadSet } from '@/models/UploadSet'
import { GeneratedHotspot, Hotspot } from '@/models/Hotspot'

export class ApiAdapter {
  private url
  constructor (url = `${process.env.VUE_APP_BACKEND_URL}/api`) {
    this.url = url
  }

  uploadScenario (id: string, fileInputUrl: string | ArrayBuffer | null, fileName: string, hotspots: Hotspot[], robotPosition: THREE.Vector3 | null, robotRotation: THREE.Quaternion | null, customerName: string, customerEmail: string, customerAffiliation: string, scenarioName: string, scenarioVersion = '1', options = {}): Promise<AxiosResponse<any>> {
    const allHotspots: (Hotspot | GeneratedHotspot)[] = hotspots.reduce((acc: (Hotspot | GeneratedHotspot)[], hotspot: Hotspot): (Hotspot | GeneratedHotspot)[] => {
      if (hotspot.interpolationOptions?.vectors) {
        acc.push(...hotspot.interpolationOptions?.vectors)
      }
      acc.push(hotspot)
      return acc
    }, [])

    const body = {
      id,
      filename: fileName,
      hotspots: allHotspots,
      robotPosition,
      robotRotation,
      customerName,
      customerMail: customerEmail,
      customerAffiliation,
      scenarioName,
      scenarioVersion,
      file: fileInputUrl
    }
    return axios.post(`${this.url}/scenarios`, body, options)
  }

  duplicateScenario (id: string, name?: string): Promise<AxiosResponse<{id: string}>> {
    return axios.post(`${this.url}/scenarios/${id}/duplicate`, { name })
  }

  downloadCncGlb (): Promise<Blob> {
    return axios({
      url: `${window.location.origin}/cnc.glb`,
      method: 'GET',
      responseType: 'blob' // important
    }).then((response) => {
      return new Blob([response.data])
    })
  }

  uploadPreviewImage (id: string, dataUrl: string): Promise < AxiosResponse < any >> {
    const body = {
      dataUrl
    }
    return axios.post(`${this.url}/scenarios/${id}/preview`, body)
  }

  loadScenario (id: string, options ?: AxiosRequestConfig | undefined): Promise < UploadSet > {
    return axios.get(`${this.url}/scenarios/${id}`, options)
      .then(response => {
        const responseData: any = response.data

        const hotspots: Hotspot[] = []
        const goals = responseData.tasks[0].goals

        let generatedHotspots: GeneratedHotspot[] = []
        for (let i = 0; i < goals.length; i++) {
          const goal = goals[i]
          if (goal.metadata?.generated) {
            generatedHotspots.push(new GeneratedHotspot(
              goal.ID,
              goal.goal_pose.pose.point.join(' '),
              goal.goal_pose.pose.orientation.value.join(' '),
              goal.approach_mode
            ))
          } else {
            if (generatedHotspots.length > 0) {
              if (!goal.metadata) goal.metadata = {}
              if (!goal.metadata.interpolationOptions) goal.metadata.interpolationOptions = {}
              goal.metadata.interpolationOptions.vectors = generatedHotspots
              generatedHotspots = []
            }
            if (goal.goal_pose.pose.orientation.Type === 'quaternion') {
              const orientation: THREE.Quaternion = new THREE.Quaternion(
                goal.goal_pose.pose.orientation.x,
                goal.goal_pose.pose.orientation.y,
                goal.goal_pose.pose.orientation.z,
                goal.goal_pose.pose.orientation.w
              )
              hotspots.push(new Hotspot(
                goal.ID,
                goal.goal_pose.pose.point.join(' '),
                orientation,
                goal.description,
                false,
                goal.approach_mode,
                goal.metadata?.interpolationOptions,
                goal.action
              ))
            } else {
              const orientation: string = goal.goal_pose.pose.orientation.value.join(' ')
              hotspots.push(new Hotspot(
                goal.ID,
                goal.goal_pose.pose.point.join(' '),
                orientation,
                goal.description,
                false,
                goal.approach_mode,
                goal.metadata?.interpolationOptions,
                goal.action
              ))
            }
          }
        }
        const point = responseData.tasks[0].base_pose.pose.point
        const orientation = responseData.tasks[0].base_pose.pose.orientation
        return new UploadSet(
          responseData.header.scenarioID,
          responseData.obstacles[0].geometry.collision.geometry.file.name,
          hotspots,
          point ? new THREE.Vector3(point.x, point.y, point.z) : null,
          orientation ? new THREE.Quaternion(orientation.x, orientation.y, orientation.z, orientation.w) : null,
          responseData.header.authorInfo.name,
          responseData.header.authorInfo.email,
          responseData.header.authorInfo.affiliation,
          responseData.header.scenarioName,
          responseData.header.version,
          responseData.obstacles[0].geometry.collision.geometry.file.data
        )
      })
  }

  listScenarios (options ?: AxiosRequestConfig | undefined): Promise < AxiosResponse < any >> {
    return axios.get(`${this.url}/scenarios`, options)
  }

  generateRobot (scenarioId: string): Promise < AxiosResponse < any >> {
    return axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/scenarios/${scenarioId}/robot`)
  }

  getUrdfStatus (url: string): Promise < AxiosResponse < any >> {
    return axios.get(url)
  }

  generateRandomId (): string {
    return Math.random().toString(36).substring(7)
  }
}
