



























































































































































































































































































































import * as THREE from 'three'
import { Component, Vue } from 'vue-property-decorator'
import draggable from 'vuedraggable'

import { Hotspot } from '@/models/Hotspot'
import { UploadSet } from '@/models/UploadSet'

import ThreeJsComponent from '@/components/ThreeJsComponent.vue'
import EditHotspotMenu from '@/components/EditHotspotMenu.vue'
import EditRobotMenu from '@/components/EditRobotMenu.vue'
import PreviewMenu from '@/components/PreviewMenu.vue'
import HelpBox from '@/components/HelpBox.vue'
import GuideBox from '@/components/GuideBox.vue'
import TimelineHotspot from '@/components/TimelineHotspot.vue'
import UserStudyDonePopup from '@/components/UserStudyDonePopup.vue'
import LanguageSwitch from '@/components/LanguageSwitch.vue'
import { ApiAdapter } from '@/apiAdapter'
import i18n from '@/plugins/i18n'

@Component({
  components: {
    ThreeJsComponent,
    EditHotspotMenu,
    EditRobotMenu,
    PreviewMenu,
    HelpBox,
    GuideBox,
    TimelineHotspot,
    UserStudyDonePopup,
    LanguageSwitch,
    draggable
  }
})
export default class ModelViewerComponent extends Vue {
  private hotspots: Hotspot[] = []
  private newHotspotDescription = ''
  private showHotspotDialog = false
  private selectedHotspotIndex = 0

  private userStudy: boolean = process.env.VUE_APP_USER_STUDY === 'true'

  private newHotspotPosition: THREE.Vector3 | null = null
  private newHotspotRotation: THREE.Euler | null = null
  private newHotspotIndex = -1

  private scenarioId = ''
  private showUploadDialog = false
  private customerName = ''
  private customerEmail = ''
  private scenarioName = ''
  private scenarioVersion = ''
  private customerAffiliation = ''
  private uploadSet: Partial<UploadSet> | null = null

  private snackbarVisible = false
  private snackbarText = ''
  private showHelpBox = true

  private fileInput: File | null = null
  private fileInputUrl: string | ArrayBuffer | null = null
  private uploadProgress = 0;

  private downloadProgress = 0;

  private robotUrl: string | null = null
  private urdfStatusUrl: string | null = null
  private robotLoading = false
  private showRobot = false

  private robotPosition: THREE.Vector3 | null = null
  private robotRotation: THREE.Quaternion | null = null
  private refineRobot = false

  private keyListener!: (this: Document, ev: KeyboardEvent) => void
  private refineHotspot: Hotspot | null = null

  private animateRobotToolOrientation = true
  private transformControlsMode: 'translate' | 'rotate' = 'translate'

  private api!: ApiAdapter

  private doneSteps = [false, false, false, false, false, false, false, false, false]
  private doneGuide = false
  private doneGuidePopup = false

  private mounted () {
    this.api = new ApiAdapter()
    if (this.$route.params.id) {
      this.scenarioId = this.$route.params.id
      console.log(`Loading ${this.scenarioId}..`)
      this.loadScenario(this.scenarioId)
    }

    this.activateKeyboardShortcuts()

    this.loadGuideSteps()
  }

  private loadGuideSteps (): void {
    const val = localStorage.getItem(`${this.scenarioId}.doneSteps`)
    if (val === null) return
    this.doneSteps = val.split(',').map((s) => (s === 'true'))
  }

  private beforeDestroy (): void {
    document.removeEventListener('keydown', this.keyListener)
  }

  private setRefineHotspot (hotspot) {
    if (this.refineHotspot !== null && hotspot === null) {
      this.completeStep(8)
    }
    this.refineHotspot = hotspot
  }

  private activateKeyboardShortcuts () {
    const handleKey = async (e) => {
      if (e.key.toLowerCase() === 's' && (e.metaKey || e.ctrlKey)) {
        this.uploadScenario()
      } else if (e.key === 'Escape') {
        if (this.selectedHotspot !== null) {
          this.toggleHotspot(this.selectedHotspot)
        }
        if (this.refineRobot) {
          this.refineRobot = false
          this.completeStep(7)
        }
      } else if (e.key === 'Enter') {
        if (this.refineHotspot !== null) {
          this.completeStep(8)
          this.refineHotspot = null
        } else if (this.refineRobot) {
          this.refineRobot = false
          this.completeStep(7)
        }
      } else if ((e.key === 'r' || e.key === 't') && e.target.tagName !== 'INPUT' && !this.showHotspotDialog) {
        if (!this.refineRobot && this.refineHotspot === null && this.selectedHotspot !== null) {
          this.refineHotspot = this.selectedHotspot
        }
        if (this.refineRobot || this.refineHotspot !== null) {
          if (e.key === 'r') this.$set(this, 'transformControlsMode', 'rotate')
          else this.$set(this, 'transformControlsMode', 'translate')
        }
      } else if ((e.key === 'ArrowLeft') && e.target.tagName !== 'INPUT' && this.selectedHotspot) {
        if (this.selectedHotspotIndex > 0) this.toggleHotspotByIndex(this.selectedHotspotIndex - 1)
      } else if ((e.key === 'ArrowRight') && e.target.tagName !== 'INPUT' && this.selectedHotspot) {
        if (this.selectedHotspotIndex + 1 <= this.hotspots.length - 1) this.toggleHotspotByIndex(this.selectedHotspotIndex + 1)
      }
    }

    this.keyListener = handleKey.bind(this)
    document.addEventListener('keydown', this.keyListener)
  }

  private clickHandler3d (position: THREE.Vector3, rotation: THREE.Euler) {
    if (this.robotPosition == null || this.robotRotation == null) {
      this.$gtag.event('placeRobot', {
        event_category: 'Task',
        event_label: this.scenarioId,
        value: Math.round(Date.now() / 1000),
        timestamp: Math.round(Date.now() / 1000)
      })
      this.robotPosition = position
      const manipulatorRotationCorrection = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), 90 * THREE.MathUtils.DEG2RAD)
      this.robotRotation = new THREE.Quaternion().setFromEuler(rotation).multiply(manipulatorRotationCorrection)
      this.completeStep(1)
    } else this.openHotspotDialog(position, rotation)
  }

  private clickHandlerRobot () {
    if (this.refineRobot) {
      this.completeStep(7)
    }
    if (this.selectedHotspot) this.toggleHotspot(this.selectedHotspot)
    this.refineRobot = !this.refineRobot
    this.transformControlsMode = 'translate'
  }

  private openHotspotDialogForIndex (index: number) {
    if (index <= 0 || index >= this.hotspots.length) return
    console.log(index)
    const before = this.hotspots[index - 1]
    const after = this.hotspots[index]
    const position: THREE.Vector3 = before.position.clone().lerp(after.position, 0.5)
    const rotation: THREE.Euler = (new THREE.Euler()).setFromQuaternion(before.quaternion.clone().slerp(after.quaternion, 0.5))
    this.openHotspotDialog(position, rotation, index)
  }

  private openHotspotDialog (position: THREE.Vector3, rotation: THREE.Euler, index?: number) {
    if (position === null) {
      this.renderSnackbar(this.$t('snackbar.clickedOutsideOfScan').toString())
      return
    }
    if (this.refineHotspot !== null || this.refineRobot) {
      this.renderSnackbar(this.$t('snackbar.noAddDuringRefine').toString())
      return
    }

    if (typeof index === 'undefined') index = this.hotspots.length - 1

    this.newHotspotPosition = position
    this.newHotspotRotation = rotation
    this.newHotspotIndex = index

    this.showHotspotDialog = true
  }

  private closeHotspotDialog () {
    this.showHotspotDialog = false
    this.newHotspotDescription = ''
    this.newHotspotPosition = null
    this.newHotspotRotation = null
    this.newHotspotIndex = -1
  }

  private addHotspot () {
    this.resetSelection()
    if (this.newHotspotIndex === -1) this.newHotspotIndex = this.hotspots.length
    if (!(!this.newHotspotPosition || !this.newHotspotRotation)) {
      const newHotspots = this.hotspots.slice()
      const newHotspot = new Hotspot(this.random(), this.newHotspotPosition, this.newHotspotRotation, this.newHotspotDescription, false)
      if (this.newHotspotIndex < this.hotspots.length) {
        newHotspots.splice(this.newHotspotIndex, 0, newHotspot)
      } else {
        newHotspots.push(newHotspot)
      }
      this.hotspots = newHotspots
      this.$gtag.event('add', {
        event_category: 'Hotspot',
        event_label: newHotspot.id,
        value: Math.round(Date.now() / 1000),
        timestamp: Math.round(Date.now() / 1000)
      })
      if (this.hotspots.length >= 3) this.completeStep(2)
    }

    this.toggleHotspotByIndex(this.newHotspotIndex)
    this.closeHotspotDialog()
  }

  private random () {
    return Math.random().toString(36).substring(7)
  }

  private resetSelection () {
    for (let i = 0; i < this.hotspots.length; i++) {
      this.hotspots[i].selected = false
    }
  }

  get selectedHotspot (): Hotspot | null {
    const selected: Hotspot[] = this.hotspots.filter((h) => h.selected)
    if (selected.length > 0) return selected[0]
    return null
  }

  private toggleHotspotByIndex (index: number) {
    if (this.refineRobot || this.refineHotspot !== null) {
      this.renderSnackbar(this.$t('snackbar.noSelectionDuringRefine').toString())
      return
    }
    if (this.showRobot) {
      this.renderSnackbar(this.$t('snackbar.noSelectionDuringPreview').toString())
      return
    }
    if (this.hotspots[index].selected) {
      this.$gtag.event('deselect', {
        event_category: 'Hotspot',
        event_label: this.hotspots[index].id,
        value: Math.round(Date.now() / 1000),
        timestamp: Math.round(Date.now() / 1000)
      })
      this.selectedHotspotIndex = -1
      this.resetSelection()
    } else {
      this.$gtag.event('select', {
        event_category: 'Hotspot',
        event_label: this.hotspots[index].id,
        value: Math.round(Date.now() / 1000),
        timestamp: Math.round(Date.now() / 1000)
      })
      this.resetSelection()
      this.hotspots[index].selected = true
      this.selectedHotspotIndex = index
    }
  }

  private toggleHotspot (hotspot: Hotspot) {
    const index = this.hotspots.indexOf(hotspot)
    if (index !== -1) this.toggleHotspotByIndex(index)
  }

  /**
   * Removes all available hotspots.
   */
  private clearHotspots () {
    this.hotspots = []
    this.newHotspotPosition = null
    this.newHotspotRotation = null
  }

  private removeHotspotByIndex (index: number) {
    if (this.refineRobot || this.refineHotspot !== null) {
      this.renderSnackbar(this.$t('snackbar.noRemoveDuringRefine').toString())
      return
    }
    this.$gtag.event('remove', {
      event_category: 'Hotspot',
      event_label: this.hotspots[index].id,
      value: Math.round(Date.now() / 1000),
      timestamp: Math.round(Date.now() / 1000)
    })
    // hotspot Array is copied to ensure the watchers can compare the old and new array
    const updatedHotspots = this.hotspots.slice()
    updatedHotspots.splice(index, 1)
    this.hotspots = updatedHotspots
  }

  private removeHotspot (hotspot: Hotspot) {
    // hotspot Array is copied to ensure the watchers can compare the old and new array
    // see Vue docs on props for details.
    const index = this.hotspots.indexOf(hotspot)
    if (index !== -1) this.removeHotspotByIndex(index)
  }

  private openUploadDialog () {
    this.showUploadDialog = true
  }

  private setUploadProgress (uploadProgress: number) {
    this.uploadProgress = uploadProgress
  }

  private setDownloadProgress (downloadProgress: number) {
    this.downloadProgress = downloadProgress
  }

  private completeStep (i: number) {
    if (!this.doneSteps[i]) {
      this.$set(this.doneSteps, i, true)
      this.$gtag.event(`completeStep${i}`, {
        event_category: 'Task',
        event_label: this.scenarioId,
        value: Math.round(Date.now() / 1000),
        timestamp: Math.round(Date.now() / 1000)
      })
    }
  }

  /**
   * Sends the current task to the optimizer and updates the urdfStatusUrl
   */
  private startOptimizer (): void {
    if (this.refineRobot || this.refineHotspot !== null) {
      this.renderSnackbar(this.$t('snackbar.noPreviewDuringRefine').toString())
      return
    }
    console.log('Starting optimizer...')
    this.uploadScenario()
    this.robotLoading = true
    this.robotUrl = null
    if (this.selectedHotspot !== null) this.toggleHotspot(this.selectedHotspot)
    this.api.generateRobot(this.scenarioId).then(response => {
      this.urdfStatusUrl = response.data.statusUrl
      this.pollUrdfStatus()
    })
  }

  /**
   * Polls the current urdfStatusUrl until it receives a 'done' response.
   * Then updates the robotUrl and enables showRobot
   */
  private pollUrdfStatus (): void {
    if (this.urdfStatusUrl) {
      this.api.getUrdfStatus(this.urdfStatusUrl).then(response => {
        if (response.data.status === 'done') {
          console.log('URDF available...')
          if (this.selectedHotspot !== null) this.toggleHotspot(this.selectedHotspot)
          this.refineRobot = false
          this.robotUrl = response.data.urdfUrl
          this.robotLoading = false
          this.showRobot = true
        } else {
          setTimeout(this.pollUrdfStatus, 2000)
        }
      })
    }
  }

  private async uploadScenario () {
    this.$gtag.event('save', {
      event_category: 'Task',
      event_label: this.scenarioId,
      value: Math.round(Date.now() / 1000),
      timestamp: Math.round(Date.now() / 1000)
    })
    localStorage.setItem(`${this.scenarioId}.doneSteps`, this.doneSteps.toString())
    let fileName: string
    if (this.fileInput) fileName = this.fileInput.name
    else fileName = this.$store.state.uploadSet.filename
    const options = { onUploadProgress: progressEvent => { this.setUploadProgress((progressEvent.loaded / progressEvent.total) * 100) } }
    this.api.uploadScenario(this.scenarioId, this.fileInputUrl, fileName, this.hotspots, this.robotPosition, this.robotRotation, this.customerName, this.customerEmail, this.customerAffiliation, this.scenarioName, this.scenarioVersion, options)
      .then(() => {
        this.renderSnackbar(this.$t('snackbar.saved').toString())
      }).catch(response => {
        console.error(response)
        this.renderSnackbar(this.$t('snackbar.saveFailed').toString())
      }).finally(() => {
        this.showUploadDialog = false
        this.uploadProgress = 0
      })
  }

  private async uploadPreviewImage (dataUrl: string) {
    await this.api.uploadPreviewImage(this.scenarioId, dataUrl)
  }

  private loadScenario (id: string) {
    this.api.loadScenario(id, { onDownloadProgress: progressEvent => { this.setDownloadProgress((progressEvent.loaded / progressEvent.total) * 100) } })
      .then((uploadSet: UploadSet) => {
        this.uploadSet = uploadSet
        this.scenarioId = uploadSet.id
        this.customerName = uploadSet.customerName
        this.customerEmail = uploadSet.customerMail
        this.customerAffiliation = uploadSet.customerAffiliation
        this.scenarioName = uploadSet.scenarioName
        this.scenarioVersion = uploadSet.scenarioVersion

        this.fileInputUrl = uploadSet.file
        this.hotspots = uploadSet.hotspots
        this.robotPosition = uploadSet.robotPosition
        this.robotRotation = uploadSet.robotRotation
      }).catch(response => {
        console.error(response)
      }).finally(() => {
        this.downloadProgress = 0
      })
  }

  private renderSnackbar (text: string) {
    this.snackbarText = text
    this.snackbarVisible = true
  }

  private copyUrlToClipboard () {
    this.copyToClipboard(window.location.href)
    this.renderSnackbar(`${this.$t('snackbar.copiedShareLink')} ${window.location.href}`)
  }

  private copyToClipboard (str: string): void {
    const el = document.createElement('textarea')
    el.value = str
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  private onContactButton (): void {
    this.$gtag.event('openQuestionnaire', {
      event_category: 'UserStudy',
      event_label: this.scenarioId,
      value: Math.round(Date.now() / 1000),
      timestamp: Math.round(Date.now() / 1000)
    })
    const formUrl = (i18n.locale === 'en' ? process.env.VUE_APP_FORM_EN : process.env.VUE_APP_FORM_DE) + this.scenarioId
    window.open(formUrl, '_blank')
  }

  private onDraggableChange (change): void {
    if (change.moved && change.moved?.newIndex !== change.moved?.oldIndex) {
      const hotspot = this.hotspots[change.moved.newIndex]
      this.$gtag.event('reorder', {
        event_category: 'Hotspot',
        event_label: hotspot.id,
        value: Math.round(Date.now() / 1000),
        timestamp: Math.round(Date.now() / 1000)
      })
      this.completeStep(6)
    }
  }

  private setTransformControlsMode (val): void {
    this.transformControlsMode = val
  }
}

