











































import { Component, Vue } from 'vue-property-decorator'
import { TaskType } from '@/models/Task'
import Stepper from '@/components/Stepper.vue'
import DashboardWrapper from '@/components/DashboardWrapper.vue'

@Component({
  components: {
    Stepper,
    DashboardWrapper
  }
})
export default class SelectTaskType extends Vue {
  categories: {imageSrc?: string, name: string, description: string, type: TaskType}[] = [
    { name: 'createTask.selectType.materialsHandling.name', description: 'createTask.selectType.materialsHandling.description', imageSrc: '/img/icons/noun_coil_3160470.svg', type: TaskType.MaterialsHandling },
    { name: 'createTask.selectType.machineTending.name', description: 'createTask.selectType.machineTending.description', imageSrc: '/img/icons/noun_Machine_3130970.svg', type: TaskType.MachineTending },
    { name: 'createTask.selectType.qualityAssurance.name', description: 'createTask.selectType.qualityAssurance.description', imageSrc: '/img/icons/noun_magnifier_782867.svg', type: TaskType.QualityAssurance },
    { name: 'createTask.selectType.dispensing.name', description: 'createTask.selectType.dispensing.description', imageSrc: '/img/icons/noun_pipette_3326450.svg', type: TaskType.Dispensing },
    { name: 'createTask.selectType.finishing.name', description: 'createTask.selectType.finishing.description', imageSrc: '/img/icons/noun_Star_981061.svg', type: TaskType.Finishing },
    { name: 'createTask.selectType.materialRemoval.name', description: 'createTask.selectType.materialRemoval.description', imageSrc: '/img/icons/noun_Saw Blade_16402.svg', type: TaskType.MaterialRemoval },
    { name: 'createTask.selectType.assembly.name', description: 'createTask.selectType.assembly.description', imageSrc: '/img/icons/noun_screw_1089328.svg', type: TaskType.Assembly },
    { name: 'createTask.selectType.welding.name', description: 'createTask.selectType.welding.description', imageSrc: '/img/icons/noun_welding_190496.svg', type: TaskType.Welding }
  ]

  private step = 1

  selectTaskType (cat: {imageSrc?: string, name: string, type: TaskType}): void {
    this.$gtag.event('selectType', {
      event_category: 'Task',
      event_label: cat.type,
      value: Math.round(Date.now() / 1000),
      timestamp: Math.round(Date.now() / 1000)
    })
    this.$store.commit('updateTaskType', cat.type)
    this.$router.push('/new/name')
  }
}
