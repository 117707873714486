import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from '@/plugins/i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@mdi/font/css/materialdesignicons.css'
import VueGtag from 'vue-gtag'

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_ID
  }
}, router)

Vue.config.productionTip = false
Vue.use(VueAxios, axios)

// Ignore model-viewer as it is an external dependency, not in vue context
Vue.config.ignoredElements = [
  'model-viewer'
]

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
