


























































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class HelpBox extends Vue {
  @Prop({
    default: false
  }) private placingRobot!: boolean

  @Prop({
    default: false
  }) private refiningHotspot!: boolean

  @Prop({
    default: false
  }) private editingHotspot!: boolean

  @Prop({
    default: false
  }) private definingPath!: boolean

  @Prop({
    default: false
  }) private showingPreview!: boolean

  @Prop({
    default: '30vw'
  }) private maxCardWidth

  private show = true
}
