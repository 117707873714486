











































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

@Component({})
export default class SideBar extends Vue {
  @PropSync('minified', {
    required: true
  })
  private mini!: boolean

  private nodeEnv = process.env.NODE_ENV

  private drawer = false

  @Prop({
    required: true
  })
  private name!: string

  @Prop()
  private photoUrl!: string
}
