import * as THREE from 'three'
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js'
import { Vue } from 'vue-property-decorator'

import HotspotLabel from '@/components/HotspotLabel.vue'
import { Hotspot } from '@/models/Hotspot'

export class HotspotMarker extends CSS2DObject {
  constructor (hotspot: Hotspot, index: number, onClick: (h: Hotspot) => void, opacity = 1, toolUrl?: string) {
    const HotspotLabelClass = Vue.extend(HotspotLabel)
    const hotspotInstance = new HotspotLabelClass({
      propsData: {
        hotspot,
        currentIndex: index
      }
    })
    hotspotInstance.$on('click', onClick)
    hotspotInstance.$mount()
    const hotspotElement = hotspotInstance.$el as HTMLElement
    super(hotspotElement)
    this.name = hotspot.id
    this.position.copy(hotspot.position)
    this.quaternion.copy(hotspot.quaternion)

    if (typeof toolUrl === 'string') {
      const loader = new STLLoader()
      loader.load(toolUrl, (toolGeo: THREE.BufferGeometry) => {
        const toolMaterial = new THREE.MeshStandardMaterial({
          color: hotspot.action?.Type === 'attach' ? new THREE.Color(1, 0.3, 0) : 'green',
          transparent: true,
          opacity
        })
        const tool = new THREE.Mesh(toolGeo, toolMaterial)
        tool.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI)
        this.add(tool)
      })
    } else {
      const coneLength = 0.1
      const coneGeo = new THREE.ConeGeometry(0.02, coneLength)
      const coneTipGeo = new THREE.BoxGeometry(0.005, 0.02, 0.02)
      const coneMaterial = new THREE.MeshStandardMaterial({
        color: hotspot.action?.Type === 'attach' ? new THREE.Color(1, 0.3, 0) : 'green',
        transparent: true,
        opacity
      })
      const coneTipMaterial = new THREE.MeshStandardMaterial({
        color: 'white',
        transparent: true,
        opacity
      })
      const cone = new THREE.Mesh(coneGeo, coneMaterial)
      const tip = new THREE.Mesh(coneTipGeo, coneTipMaterial)
      cone.name = `${hotspot.id}-cone`
      cone.position.add(new THREE.Vector3(0, 0, 1).multiplyScalar(coneLength / 2))
      cone.rotateOnAxis(new THREE.Vector3(1, 0, 0), -Math.PI / 2)
      cone.add(tip)
      tip.position.add(new THREE.Vector3(0, -(coneLength / 2 - 0.009), 0.01))

      this.add(cone)
    }
  }

  public setOpacity (val: number) {
    this.traverse((obj: THREE.Object3D) => {
      if (obj instanceof THREE.Mesh) {
        obj.material.opacity = val
      }
    })
  }

  public dispose (): void {
    this.traverse((obj: THREE.Object3D) => {
      if (obj instanceof THREE.Mesh) {
        obj.material.dispose()
        obj.geometry.dispose()
      }
    })
  }
}
