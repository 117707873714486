import { Hotspot } from '@/models/Hotspot'

export class UploadSet {
  id!: string;
  filename!: string;
  hotspots: Hotspot[];
  robotPosition: THREE.Vector3 | null;
  robotRotation: THREE.Quaternion | null;
  customerName!: string;
  customerMail!: string;
  customerAffiliation!: string;
  scenarioName!: string;
  scenarioVersion!: string;
  file!: string | ArrayBuffer | null;

  constructor (id: string, filename: string, hotspots: Hotspot[], robotPosition: THREE.Vector3 | null, robotRotation: THREE.Quaternion | null, customerName: string, customerMail: string, customerAffiliation: string, scenarioName: string, scenarioVersion: string, file: string | ArrayBuffer | null) {
    this.id = id
    this.filename = filename
    this.hotspots = hotspots
    this.robotPosition = robotPosition
    this.robotRotation = robotRotation
    this.customerName = customerName
    this.customerMail = customerMail
    this.customerAffiliation = customerAffiliation
    this.scenarioName = scenarioName
    this.scenarioVersion = scenarioVersion
    this.file = file
  }
}
