import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#252644',
        secondary: '#FFF',
        accent: '#4FB58C',
        error: '#b71c1c',
        warning: '#FCBD0C'
      }
    }
  }
})
