




































































































































import { Component, Vue } from 'vue-property-decorator'
import { ApiAdapter } from '@/apiAdapter'
import Stepper from '@/components/Stepper.vue'
import DashboardWrapper from '@/components/DashboardWrapper.vue'

@Component({
  components: {
    Stepper,
    DashboardWrapper
  }
})
export default class UploadEnvironment extends Vue {
  private fileInput: File | null = null
  private fileInputUrl: string | ArrayBuffer | null = ''
  private thirdPartyFileUrl = ''
  private step = 3
  private iconColor = 'grey'
  private defaultFile = process.env.VUE_APP_USER_STUDY === 'true'
  private loading = false

  private apiAdapter: ApiAdapter = new ApiAdapter()

  confirm (): void {
    this.$gtag.event('uploadScan', {
      event_category: 'Task',
      value: Math.round(Date.now() / 1000),
      timestamp: Math.round(Date.now() / 1000)
    })
    this.loading = true
    this.uploadFile()
  }

  private hoverStart (): void {
    console.log('hoverStart')
    this.iconColor = 'accent'
  }

  private hoverStop (): void {
    this.iconColor = 'grey'
  }

  private addFile (e: DragEvent) {
    const droppedFiles = e.dataTransfer?.files
    if (!droppedFiles) return
    this.fileInput = droppedFiles[0]
    this.uploadFile()
  }

  private async uploadFile (): Promise<void> {
    if (this.fileInput === null) {
      const name = this.$store.state.newTaskName
      const { id } = (await this.apiAdapter.duplicateScenario('default', name)).data
      localStorage.setItem('task', id)
      this.loading = false
      this.$router.push('/tasks/' + id)
      return
    }

    const filename = this.fileInput.name
    const fr = new FileReader()

    fr.readAsDataURL(this.fileInput)
    fr.onload = () => {
      this.fileInputUrl = fr.result
      const taskId = this.apiAdapter.generateRandomId()

      console.log('Creating ' + taskId + '...')
      const name = this.$store.state.newTaskName
      this.apiAdapter.uploadScenario(taskId, fr.result, filename, [], null, null, process.env.USER_NAME, 'user@therobotcompany.de', 'Customer Inc.', name).then((res: any) => {
        localStorage.setItem('task', taskId)
        this.loading = false
        this.$router.push('/tasks/' + taskId)
      }, (err) => {
        console.log(err)
      })
    }
  }

  private loadThirdPartyUrl () {
    const name = this.$store.state.newTaskName
    const taskId = this.apiAdapter.generateRandomId()
    this.apiAdapter.uploadScenario(taskId, this.thirdPartyUrl, 'scan', [], null, null, process.env.USER_NAME, 'user@therobotcompany.de', 'Customer Inc.', name).then((res: any) => {
      localStorage.setItem('task', taskId)
      this.loading = false
      this.$router.push('/tasks/' + taskId)
    }, (err) => {
      console.log(err)
    })
  }

  get thirdPartyUrl (): string {
    return this.thirdPartyFileUrl
  }

  set thirdPartyUrl (val: string) {
    if (val.indexOf('poly.cam/capture') !== -1) {
      const parts = val.split('/')
      const id = parts[parts.length - 1]
      this.thirdPartyFileUrl = `https://storage.polycam.io/captures/${id}/poly.glb`
    } else if (val.endsWith('.glb') || val.endsWith('.gltf')) {
      this.thirdPartyFileUrl = val
    }
  }
}
