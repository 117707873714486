


































































































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

@Component({})
export default class PreviewMenu extends Vue {
  @Prop({
    required: true
  }) private scenarioName!: string

  @Prop({
    required: true
  }) private scenarioId!: string

  @Prop({
    default: false
  }) private robotLoading!: string

  @Prop() private backToEditor!: () => void
  @Prop() private hotspotsLength!: number

  private headers = [
    {
      text: 'Name',
      align: 'start',
      value: 'name'
    },
    {
      text: 'Value',
      align: 'start',
      value: 'value'
    }
  ]

  private kpis = [
    {
      name: this.$t('previewMenu.kpis.repetitions').toString(),
      value: `~${3600 / (this.hotspotsLength * 4)}`
    },
    {
      name: this.$t('previewMenu.kpis.duration').toString(),
      value: `~${this.hotspotsLength * 4}`
    }
  ]

  private modules = [
    {
      id: 'b-116',
      count: 1
    },
    {
      id: 'd-116',
      count: 4
    },
    {
      id: 'i-116-425',
      count: 1
    },
    {
      id: 'c-85-116',
      count: 1
    },
    {
      id: 'i-85-300',
      count: 2
    },
    {
      id: 'd-85',
      count: 3
    }
  ]

  private moduleTypes = {
    b: 'Base',
    l: 'Link',
    i: 'Link',
    c: 'Connector',
    d: 'Driver'
  }

  private checks: ({name: string, status: boolean | null, tip: string})[] = [
    {
      name: this.$t('previewMenu.checks.reachable.title').toString(),
      status: null,
      tip: this.$t('previewMenu.checks.reachable.tip').toString()
    },
    {
      name: this.$t('previewMenu.checks.kinematics.title').toString(),
      status: null,
      tip: this.$t('previewMenu.checks.kinematics.tip').toString()
    },
    {
      name: this.$t('previewMenu.checks.loads.title').toString(),
      status: null,
      tip: this.$t('previewMenu.checks.loads.tip').toString()
    }
  ]

  @Watch('robotLoading')
  private onRobotLoading (val: boolean) {
    this.checks.forEach((check) => {
      check.status = true
    })
  }

  private onButton () {
    window.open(this.$t('previewMenu.contactButtonLink').toString(), '_blank')
  }

  get allChecks (): boolean | null {
    for (let i = 0; i < this.checks.length; i++) {
      if (this.checks[i].status === null) return null
      if (!this.checks[i].status) return false
    }
    return true
  }
}
