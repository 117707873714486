











import { Component, Vue } from 'vue-property-decorator'
import DashboardWrapper from '@/components/DashboardWrapper.vue'

@Component({
  components: {
    DashboardWrapper
  }
})
export default class Teaching extends Vue {
  iframeUrl = process.env.VUE_APP_NODE_RED_URL
}
