










































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class GuideBox extends Vue {
  @Prop({
    default: false
  }) private placingRobot!: boolean

  @Prop({
    default: '27vw'
  }) private maxCardWidth

  @Prop({
    default: '60vh'
  }) private maxCardHeight

  @Prop({
    required: true
  }) private doneSteps!: boolean[]

  @Prop() private onDone!: () => void
  @Prop() private done!: boolean
  @Prop() private completeStep!: (i: number) => void
  @Prop() private onContactButton!: () => void

  private steps = [
    { step: 1, button: true },
    { step: 2 },
    { step: 3 },
    { step: 4 },
    { step: 5 },
    { step: 6 },
    { step: 7 },
    { step: 8 },
    { step: 9 }
  ]

  private show = true
  private step = 1

  @Watch('doneSteps', { deep: true })
  onDoneStepsChanged (): void {
    this.step = this.doneSteps.indexOf(false) + 1
    if (!this.done && this.doneSteps.filter((b) => b).length === this.doneSteps.length) {
      setTimeout(this.onDone, 1000)
    }
  }

  private onButton (): void {
    this.completeStep(0)
  }
}
