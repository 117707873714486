














import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class RobotLabel extends Vue {
  @Prop() private onClick!: (e: string | string[]) => void

  private emitClick (e: string | string[]) {
    if (this.onClick) this.onClick(e)
    else this.$emit('click', e)
  }
}
