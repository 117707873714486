









































import { Component, Prop, Vue } from 'vue-property-decorator'
import LanguageSwitch from '@/components/LanguageSwitch.vue'

@Component({
  components: {
    LanguageSwitch
  }
})
export default class UserStudyDonePopup extends Vue {
  @Prop({
    default: false
  }) open!: boolean

  @Prop({
    default: '50vw'
  }) width!: number

  @Prop({
    required: true
  }) onButton!: () => void

  @Prop({
    required: true
  }) onContactButton!: () => void
}
