











































import { Component, Vue } from 'vue-property-decorator'
import { TaskType } from '@/models/Task'
import Stepper from '@/components/Stepper.vue'
import DashboardWrapper from '@/components/DashboardWrapper.vue'

@Component({
  components: {
    Stepper,
    DashboardWrapper
  }
})
export default class NameTask extends Vue {
  private name = ''

  private mounted () {
    this.name = TaskType[this.$store.state.newTaskType] + 'Task'
  }

  private confirm (): void {
    this.$gtag.event('giveName', {
      event_category: 'Task',
      value: this.name
    })
    this.$store.commit('updateTaskName', this.name)
    this.$router.push('/new/environment')
  }
}
