import { TaskType } from '@/models/Task'
import { UploadSet } from '@/models/UploadSet'

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    newTaskType: TaskType.Welding,
    newTaskName: '',
    uploadSet: new UploadSet('', '', [], null, null, '', '', '', '', '', null)
  },
  mutations: {
    updateTaskType (state, taskType: TaskType) {
      state.newTaskType = taskType
    },
    updateTaskName (state, name: string) {
      state.newTaskName = name
    },
    updateUploadSet (state, uploadSet: UploadSet) {
      state.uploadSet = uploadSet
    }
  },
  actions: {
  },
  modules: {
  }
})
