import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  de: {
    snackbar: {
      noRemoveDuringRefine: 'Du kannst einen Wegpunkt nicht entfernen während Du ihn verfeinerst. Klicke vorher auf "Bestätigen".',
      noAddDuringRefine: 'Du kannst keinen neuen Wegpunkt hinzufügen während Du einen Wegpunkt oder den Roboter verfeinerst. Klicke vorher auf "Bestätigen".',
      noPreviewDuringRefine: 'Du kannst den Vorschau-Modus nicht starten während Du einen Wegpunkt oder den Roboter verfeinerst. Klicke vorher auf "Bestätigen".',
      noSelectionDuringRefine: 'Du kannst keinen anderen Wegpunkt auswählen während Du einen Wegpunkt verfeinerst. Klicke vorher auf "Bestätigen".',
      noSelectionDuringPreview: 'Du kannst keinen anderen Wegpunkt auswählen während Du im Vorschau Modus bist. Klicke vorher auf "Zurück zum Editor".',
      saved: 'Speichern erfolgreich!',
      saveFailed: 'Speichern fehlgeschlagen! Bitte versuche es später noch einmal.',
      clickedOutsideOfScan: 'Du hast außerhalb des 3D-Scans geklickt.',
      copiedShareLink: 'Link zum Teilen ins Clipboard kopiert:'
    },
    guide: {
      title: 'Folge diesen Schritten:',
      doneTitle: 'Du hast alle Schritte erledigt!',
      step1: {
        title: 'Die Kamera steuern',
        subtitle: 'Ziehen & Zoomen',
        continue: 'Weiter',
        description: [
          'Probiere folgendes:',
          '<strong>Klicke und ziehe</strong> um die Kamera zu drehen!',
          '<strong>Zoome</strong> um rein oder raus zu zoomen.</strong>',
          '<strong>Rechtsklicke und ziehe</strong> um die Kamera seitlich zu verschieben.',
          '',
          'Klicke auf <strong>Weiter</strong> wenn du damit fertig bist!'
        ].join('<br>')
      },
      step2: {
        title: 'Den Roboter Fuß platzieren',
        subtitle: 'Doppel-Klick auf das 3D Modell',
        description: [
          'Beginne damit, den Roboter zu platzieren.',
          '',
          '<strong>Doppel-Klicke</strong> auf das 3D Modell (Umgebung-Scan) um deinen Roboter dort zu platzieren.',
          '',
          'Das muss nicht perfekt sein. Die exakte Position wirst Du später anpassen.'
        ].join('<br>')
      },
      step3: {
        title: 'Roboter Pfad vorgeben',
        subtitle: 'Doppel-Klick auf das 3D Modell',
        description: [
          'Jetzt <strong>definierst du den Pfad</strong> den der Roboter mit seinem Greifer (dem Endeffektor) abfahren soll.',
          '',
          'Platziere dafür mindestens 3 Wegpunkte:',
          '1. Einen dort wo der Roboter die fertigen Teile <strong>in der CNC Fräse greifen soll</strong>.',
          '2. Einen dort wo das gegriffene Teil <strong>abgelegt</strong> werden soll.',
          '3. Einen wo der <strong>Roboter wartet</strong> während die CNC Fräse läuft.',
          '',
          '<strong>Doppel-Klicke</strong> auf das 3D Modell (Umgebung-Scan) um die jeweiligen Wegpunkte <strong>zu platzieren</strong>.',
          'Deren genaue Position und Orientierung kannst Du später anpassen.',
          '',
          '<strong>Optional:</strong>',
          '- Platziere <strong>zusätzliche Wegpunkte</strong> um einen detaillierteren Pfad vorzugeben.',
          '- Gib den Wegpunkten eine <strong>Beschreibung</strong> (z.B.: "Teil greifen")'
        ].join('<br>')
      },
      step4: {
        title: 'Vorschau anzeigen',
        subtitle: 'Klicke "Vorschau"',
        description: [
          'Werfe einen Blick auf das was du bisher geschafft hast, indem du den <strong>Vorschau Modus</strong> öffnest.',
          '',
          '<strong>Klicke</strong> auf den <strong>"Vorschau"</strong> Button oben rechts.',
          '',
          'Im Vorschau Modus zeigt der <strong>farbige Kegel</strong> (Endeffektor Markierung, hier ein Greifer) an, welche <strong>Endeffektor Bewegung</strong> du aktuell definiert hast.',
          '',
          '<strong>Hinweis:</strong>',
          'Die <strong>Roboter Animation ist ein experimentelles Feature</strong>. Der animierte Roboter vermeidet noch keine Kollisionen und bewegt sich nicht immer richtig. Du kannst aber auf die <strong>Endeffektor Markierung vertrauen</strong>.',
          '',
          'Wenn du fertig bist klicke auf <strong>Zurück zum Editor</strong> um weiter zu machen.'
        ].join('<br>')
      },
      step5: {
        title: 'Endeffektor Aktion Setzen',
        subtitle: 'Wegpunkt auswählen & Tool (Endeffektor) ändern',
        description: [
          'Noch weiß der Roboter nicht wann er seinen <strong>Greifer öffnen oder schließen</strong> soll. Lass uns das ändern!',
          '',
          '<strong>Wähle einen Wegpunkt</strong> aus indem du in der 3D Szene oder unten in der Timeline darauf klickst.',
          'Rechts erscheint dann das Wegpunkt Menü. Ändere dort den Wert für <strong>Tool (Endeffector)</strong>.',
          '',
          '<strong>Aktivieren (orange)</strong> aktiviert den Endeffektor. In unserem Beispiel: zugreifen.',
          '<strong>Deaktivieren (grün)</strong> deaktiviert den Endeffektor. In unserem Beispiel: Greifer öffnen.'
        ].join('<br>')
      },
      step6: {
        title: 'Annäherungsmodus Setzen',
        subtitle: 'Wegpunkt auswählen & "Annäherungsmodus" ändern',
        description: [
          'Wie bewegt der Roboter den Endeffektor (Greifer) von einem Wegpunkt (A) zu einem anderen (B)?',
          'Das kannst Du festlegen indem Du den <strong>Annäherungsmodus</strong> des letzteren Wegpunktes (B) änderst.',
          '',
          'Tipp: Das bedeutet, dass der Annäherungsmodus des ersten Wegpunktes in der Timeline (Nr. 0) nicht geändert werden kann.',
          '',
          '<strong>Wähle</strong> einen Wegpunkt unten in der Timeline aus & ändere seinen Wert für <strong>Annäherungsmodus</strong>.',
          '',
          'Annäherungsmodi erklärt:',
          '<strong>Point to Point (gestrichelte Linie)</strong> lässt den Roboter den Endeffektor von A nach B bewegen wie er möchte. (Standard)',
          '<strong>Linear (durchgezogene Linie)</strong> zwingt den Roboter dazu, den Endeffektor auf direktem Weg von A nach B zu bewegen.',
          '<strong>Follow Surface</strong> bewegt den Endeffektor von A nach B entlang des 3D Modells (fortgeschritten, nützlich z.B.: um Kleber auf eine Fläche aufzutragen).'
        ].join('<br>')
      },
      step7: {
        title: 'Wegpunkt Reihenfolge ändern',
        subtitle: 'Verschiebe Wegpunkt in Timeline',
        description: [
          'Lass uns als nächstes die Reifenfolge der Wegpunkte in der Timeline ändern.',
          '',
          '<strong>Ziehe dafür einen Wegpunkt</strong> in der Timeline <strong>nach links oder rechts</strong>.',
          'In der 3D Szene siehst du die aktuelle Reihenfolge an den farbigen Linien.'
        ].join('<br>')
      },
      step8: {
        title: 'Roboter Platzierung verbessern',
        subtitle: 'Klicke auf "Robot", Verschiebe, Drehe & Bestätige mit "Enter"',
        description: [
          'Als wir in Schritt 2 den Roboter platziert haben, wussten wir noch nicht, welche Wegpunkte er erreichen muss. Jetzt wo wir das wissen, kannst Du die <strong>Positionierung des Roborters verbessern</strong>.',
          '',
          '<strong>Tipp:</strong> Am besten platziert man den Roboter dort, wo er alle Wegpunkt ungefähr gleich gut erreichen kann und er nicht im Weg ist. Wenn nötig kannst du ihn auch in der Luft platzieren.',
          '',
          '<strong>Klicke auf "Robot"</strong> in der 3D Szene dort wo der Roboter platziert wurde.',
          '',
          'Benutze die <strong>farbigen Pfeile</strong> um den Roboter zu verschieben.',
          '<strong>Drücke die R-Taste</strong> um die <strong>Orientierung</strong> des Roboter Fußes zu ändern.',
          '<strong>Drücke die T-Taste</strong> um wieder die <strong>Position</strong> des Roboter Fußes zu ändern.',
          '',
          'Um zu bestätigen drücke <strong>ENTER</strong>.'
        ].join('<br>')
      },
      step9: {
        title: 'Wegpunkte verfeinern',
        subtitle: '"Platzierung Verfeinern" klicken, Wegpunkt verschieben & Bestätige mit "Enter"',
        description: [
          'Was ist wenn du die <strong>Position oder Orientierung</strong> eines Wegpunktes <strong>ändern möchtest</strong>? Du kannst sie verfeinern!',
          '',
          '<strong>Wähle den Wegpunkt</strong> aus. Dann <strong>klicke auf Platzierung verfeinern</strong> um damit zu beginnen.',
          '',
          'Benutze die <strong>farbigen Pfeile</strong> um den Wegpunkt zu verschieben.',
          '<strong>Klicke "Drehen" (oder R-Taste)</strong> um die <strong>Orientierung</strong> des Endeffektors an diesem Wegpunkt zu ändern.',
          '<strong>Klicke "Verschieben" (oder die T-Taste)</strong> um die <strong>Position</strong> des Wegpunktes zu ändern.',
          '',
          'Um zu bestätigen klicke <strong>Bestätigen</strong> oder drücke ENTER.'
        ].join('<br>')
      }
    },
    buttons: {
      save: 'Speichern',
      preview: 'Vorschau',
      backToEditor: 'Zurück zum Editor',
      contact: 'Experten Kontaktieren',
      done: 'Weiter zum Fragebogen',
      doneShort: 'Fragebogen',
      generating: 'Generiert...',
      copyShareLink: 'Link zum Teilen kopieren'
    },
    editHotspotMenu: {
      remove: 'Entfernen',
      refine: 'Platzierung Verfeinern',
      confirmRefine: 'Bestätigen',
      approachMode: 'Annäherungs-Modus',
      toolAction: 'Tool (Endeffektor)',
      refinePosition: 'Verschieben',
      refineOrientation: 'Drehen'
    },
    previewMenu: {
      contactButtonLink: 'https://robco.de/de/kontakt/',
      contactButtonText: 'Nächste Schritte besprechen!',
      checks: {
        reachable: {
          title: 'Alle Wegpunkte erreichbar',
          tip: 'Versuche entweder den Roboter näher an die Wegpunkte oder die Wegpunkte näher an den Roboter zu schieben.'
        },
        kinematics: {
          title: 'Kinematiken lösbar',
          tip: 'Hol dir Hilfe von einem unserer Experten.'
        },
        loads: {
          title: 'Belastung innerhalb Limits',
          tip: 'Hol dir Hilfe von einem unserer Experten.'
        }
      },
      kpis: {
        repetitions: 'Wiederholungen pro Stunde',
        duration: 'Sekunden pro Wiederholung'
      },
      kpisTitle: 'Task Kennzahlen:',
      howToFix: 'Um zu beheben:',
      checking: 'Überprüft Umsetzbarkeit..',
      feasible: 'Dieser Task ist umsetzbar!',
      problem: 'Dieser Task hat ein Problem!',
      robotModulesTitle: 'Roboter Module'
    },
    dashboard: {
      title: 'Roboter Tasks (Aufgaben)',
      create: 'Task erstellen',
      teaching: 'Roboter Teaching',
      lastEdited: 'zuletzt bearbeitet'
    },
    userStudy: {
      popup: {
        title: 'Willkommen zu dieser User Study! (~15 min)',
        section1: {
          text: 'Diese User Study ist Teil einer Bachelorarbeit über <strong>Task Specification for Modular Robots</strong> in Zusammenarbeit mit dem Startup RobCo - The Robot Company. RobCo hat einen <strong>modularen Roboterarm entwickelt</strong>, der SMEs dabei hilft <strong>Aufgaben zu automatisieren.</strong><br><br>Dieses Software Tool hilft dir herauszufinden, welcher Roboter von RobCo am besten dafür geeignet ist, deinen Task zu automatisieren.'
        },
        section3: {
          title: 'Deine Rolle in der User Study',
          text: [
            'Du bist <strong>Produktionsleiter</strong> bei der Krüger Metallteile GmbH die mit CNC Maschinen Metallteile für Spezialanwendungen produziert. Durch COVID-19 ist der Druck gestiegen die Produktion effizienter zu gestalten. Vor allem <strong>Material in die CNC Maschinen einführen und fertige Teile herausnehmen</strong> (Maschinenbeschickung) ist aktuell <strong>ineffizient.</strong><br><br>Dein Ziel ist es herauszufinden, <strong>ob ein RobCo Roboter diese Aufgabe automatisiert ausführen kann.</strong> Dafür wirst du nun einen <strong>neuen Roboter Task erstellen</strong> bzw. definieren.',
            'Um zu beginnen klicke auf <strong>Start</strong> und beginne dann indem du auf <strong>Task erstellen</strong> klickst.'
          ].join('<br>')
        },
        section2: {
          title: 'Wie Du teilnehmen kannst',
          text: [
            'Diese User Study dauert ungefähr <strong>15 Minuten</strong>. Benutze bitte einen <strong>Computer oder Laptop</strong> und entweder <strong>Firefox der Chrome</strong> als Browser.',
            'Die User Study hat <strong>2 Schritte</strong>:',
            '1. <strong>Dieses Software Tool benutzen</strong> indem Du einen Roboter Task erstellst.',
            '2. <strong>Ein Formular ausfühllen</strong> (den Link dazu erhältst Du später).'
          ].join('<br>')
        },
        start: 'Start'
      },
      donePopup: {
        title: 'Du hast den ersten Schritt geschafft! (75%)',
        text: [
          'Falls du bereits zufrieden mit deinem erstellten Task bist, klicke auf <strong>Weiter zum Fragebogen</strong>. Du wirst zu einem Formular mit ein paar <strong>Fragen zu deiner Erfahrung</strong> weitergeleitet.',
          '',
          '<strong>Aber,</strong> Du kannst stattdessen auch <strong>weiter an deinem Task arbeiten</strong> bis Du glücklich damit bist. Der <strong>Weiter zum Fragebogen</strong> Button ist auch <strong>in der Hilfe Box (oben links)</strong> verfügbar.',
          '',
          'Danke, dass Du bis hierhin dabei geblieben bist!'
        ].join('<br>'),
        continue: 'Zurück zum Editor'
      }
    },
    createTask: {
      selectType: {
        short: 'Task Typ Auswählen',
        title: 'Welche Art von Task möchtest du automatisieren? (Applikation)',
        materialsHandling: {
          name: 'Material­umschlag',
          description: 'Entnahme, Palettieren, etc.'
        },
        machineTending: {
          name: 'Maschinen­beschickung',
          description: 'Zuführen und entnehmen von Material/Teilen'
        },
        qualityAssurance: {
          name: 'Qualitäts­kontrolle',
          description: 'z.B.: Messungen'
        },
        dispensing: {
          name: 'Dispensieren',
          description: 'z.B.: Auftragen von Kleber'
        },
        finishing: {
          name: 'Endbearbeitung',
          description: 'Polieren, Schleifen, etc.'
        },
        materialRemoval: {
          name: 'Material­entfernung',
          description: 'z.B.: Fräsen'
        },
        assembly: {
          name: 'Montage',
          description: 'z.B.: Veschrauben'
        },
        welding: {
          name: 'Schweißen',
          description: 'z.B.: Löten'
        }
      },
      nameTask: {
        short: 'Neuen Task benennen',
        continue: 'Weiter',
        title: 'Gib dem neuen Roboter Task einen Namen!'
      },
      uploadEnvironmentScan: {
        short: 'Umgebungsscan hinzufügen',
        title: 'Füge eine Datei mit einem Umgebungsscan hinzu!',
        defaultTitle: 'Wir haben eine Umgebungsscan-Datei hinterlegt!',
        continue: 'Weiter',
        defaultContinue: 'Weiter mit hinterlegter Datei',
        selectFile: 'Wähle eine .glb oder .gltf Datei aus',
        importFile: 'URL zu einer .glb/.gltf Datei ODER eine poly.cam URL',
        dropFile: 'Ziehe hier eine Datei hin!',
        uploadFileTitle: 'Lade eine Datei hoch:',
        importFileTitle: 'Importiere eine Datei per URL:',
        defaultFileTitle: 'Wähle eine Vorlage aus:'
      }
    },
    labels: {
      preview: 'Vorschau Modus',
      editor: 'Editor',
      refineHotspot: 'Wegpunkt verfeinern'
    },
    popups: {
      addHotspot: {
        title: 'Neuen Wegpunkt hinzufügen',
        placeholder: 'Wegpunkt Beschreibung'
      }
    }
  },
  en: {
    snackbar: {
      noRemoveDuringRefine: 'You can\'t remove a Path Point while refining. Press "ENTER" to confirm first.',
      noAddDuringRefine: 'You can\'t add a new Path Point while refining. Press "ENTER" to confirm first.',
      noPreviewDuringRefine: 'You can\'t start Preview Mode while refining a path point or robot. Press "Enter" to confirm first.',
      noSelectionDuringRefine: 'You can\'t change the selection while refining a path point. Press "Enter" to confirm first.',
      noSelectionDuringPreview: 'You can\'t select a path point in Preview Mode. Click "Back to Editor" first.',
      saved: 'Save was successful!',
      saveFailed: 'Save failed! Please try again later.',
      clickedOutsideOfScan: 'You clicked outside of the 3D scan.',
      copiedShareLink: 'Copied shareable URL to your clipboard:'
    },
    guide: {
      title: 'Follow these steps:',
      doneTitle: 'You completed all steps!',
      step1: {
        title: 'Moving the camera around',
        subtitle: 'Drag & Zoom',
        continue: 'Continue',
        description: [
          'Try this:',
          '<strong>Drag</strong> to move the camera around!',
          '<strong>Zoom</strong> to zoom in or out.</strong>',
          '<strong>Drag with right click</strong> to shift the camera side to side.',
          '',
          'Click <strong>Continue</strong> when you are done!'
        ].join('<br>')
      },
      step2: {
        title: 'Placing the robot base',
        subtitle: 'Double Click on the 3D Model',
        description: [
          'Let\'s start by deciding where we want to place the base of the robot.',
          '',
          '<strong>Double Click</strong> somewhere on the 3D Model (Environment Scan) to place your robot.',
          '',
          'No need to get this perfect. We will update the exact position later.'
        ].join('<br>')
      },
      step3: {
        title: 'Defining a Path for the Robot',
        subtitle: 'Double Click on the 3D Model',
        description: [
          'Now we will <strong>define the path</strong> that the robot should move along with it\'s gripper (the tool that grabs the parts).',
          '',
          'To do this, we want to place at least 3 Path Points:',
          '1. One where the robot will <strong>pick up</strong> the finished metal <strong>parts inside the CNC machine</strong>.',
          '  2. One where the picked up part should be <strong>dropped off</strong>.',
          '3. One <strong>where the robot will wait</strong> while the CNC machine is running.',
          '',
          '<strong>Double Click</strong> somewhere on the 3D Model (Environment Scan) <strong>to place</strong> each Path Point.',
          'You can refine the exact placement later.',
          '',
          '<strong>Optional:</strong>',
          '- Place <strong>additional Path Points</strong> to define a more detailed path.',
          '- Give the Path Points a <strong>description</strong> (e.g. "pick up part")'
        ].join('<br>')
      },
      step4: {
        title: 'Check out Preview',
        subtitle: 'Click "Preview"',
        description: [
          'Let\'s take a look at what we have done so far by opening the <strong>Preview Mode</strong>.',
          '',
          '<strong>Click</strong> on the <strong>"Preview"</strong> button in the top right.',
          '',
          'The little <strong>colored cone</strong> (tool indicator) represents <strong>the movement</strong> you have specified for the robot\'s tool (in our case a gripper).',
          '',
          '<strong>Warning:</strong>',
          'The <strong>robot animation is an experimental feature</strong>. The robot does not yet avoid collisions and sometimes it\'s movement is off. You can always <strong>trust the tool indicator</strong>.',
          '',
          'When you are done click <strong>Back to Editor</strong> to continue.'
        ].join('<br>')
      },
      step5: {
        title: 'Set Tool Action',
        subtitle: 'Select Path Point & Change "Tool Action"',
        description: [
          'The robot does not yet know when to <strong>open or close it\'s gripper</strong>. Let\'s change that!',
          '',
          '<strong>Select</strong> a Path Point by clicking on it in the timeline or in the 3D scene.',
          'In the menu on the right, choose a different value for <strong>Tool (Endeffector)</strong>.',
          '',
          '<strong>Activate (orange)</strong> activates the tool. In this example: grabbing a metal part.',
          '<strong>Deactivate (green)</strong> deactivates the tool. In this example: letting go of a part.'
        ].join('<br>')
      },
      step6: {
        title: 'Set Approach Mode',
        subtitle: 'Select Path Point & Change "Approach Mode"',
        description: [
          'How does the robot move it\'s tool (gripper) from one Path Point (A) to another (B)?',
          'We can specify this by changing the <strong>Approach Mode</strong> of the Path Point it is moving towards (B).',
          '',
          'Tip: This means you can\'t change the Approach Mode for the first Path Point (number 0) in the timeline.',
          '',
          '<strong>Select</strong> a Path Point in the timeline below & change the value for <strong>Approach Mode</strong>.',
          '',
          'Approach Modes explained:',
          '<strong>Point to Point (dashed line)</strong> lets the robot move it\'s tool to this Path Point how it wants. (the default)',
          '<strong>Linear (normal line)</strong> makes the robot move it\'s tool toward the Path Point in a straight line.',
          '<strong>Follow Surface</strong> moves the tool toward the Path Point along the 3D model (advanced feature, e.g. useful for applying glue to a curved surface).'
        ].join('<br>')
      },
      step7: {
        title: 'Change Path Point Order',
        subtitle: 'Drag Path Point in Timeline',
        description: [
          'Let\'s try to change the order of our Path Points in the timeline.',
          '',
          '<strong>Drag a Path Point</strong> in the Timeline <strong>left or right</strong> to change the order.',
          'The lines between Path Points in the 3D scene show you the current path.'
        ].join('<br>')
      },
      step8: {
        title: 'Repositioning the robot',
        subtitle: 'Click on "Robot", Move, Rotate & Press "Enter"',
        description: [
          'When we placed the robot in step 2, we didn\'t yet know what points it will have to reach. Now that we know, let\'s <strong>improve the robots position</strong>.',
          '',
          '<strong>Tip:</strong> A good position for the robot is one, where it can reach all Path Points similarly well without getting in the way of anything (like a CNC machine). You can also place it in mid-air if that helps.',
          '',
          '<strong>Click on "Robot"</strong> in the 3D scene where the robot is currently placed to reposition it.',
          '',
          'Use the <strong>colored arrows</strong> to reposition the robot.',
          '<strong>Press the R-key</strong> to change the <strong>orientation</strong> of the robot.',
          '<strong>Press the T-key</strong> to go back to changing the <strong>position</strong>.',
          '',
          'To finish press <strong>ENTER</strong>.'
        ].join('<br>')
      },
      step9: {
        title: 'Refining a Path Point',
        subtitle: 'Click "Refine Placement", Move Point & Press "Enter"',
        description: [
          'What if you want to <strong>change the position or orientation</strong> of a Path Point? You can refine it!',
          '',
          '<strong>Select</strong> a Path Point. Then <strong>click Refine Placement</strong> to start refining.',
          '',
          'Use the <strong>colored arrows</strong> to move the Path Point where you want it.',
          '<strong>Click "Rotate" (or R-key)</strong> to refine the <strong>orientation</strong> of the gripper at this Path Point.',
          '<strong>Click "Move" (or T-key)</strong> to refine the <strong>position</strong> of the path point.',
          '',
          'To finish, click <strong>Confirm</strong> or press ENTER'
        ].join('<br>')
      }
    },
    buttons: {
      save: 'Save',
      preview: 'Preview',
      backToEditor: 'Back to Editor',
      done: 'Continue to Questionnaire',
      contact: 'Talk to Expert',
      doneShort: 'Questionnaire',
      generating: 'Generating...',
      copyShareLink: 'Copy share link'
    },
    editHotspotMenu: {
      remove: 'Remove',
      refine: 'Refine Placement',
      confirmRefine: 'Confirm',
      approachMode: 'Approach Mode',
      toolAction: 'Tool (Endeffector)',
      refinePosition: 'Move',
      refineOrientation: 'Rotate'
    },
    previewMenu: {
      contactButtonLink: 'https://robco.de/en/contact/',
      contactButtonText: 'Talk to us about Next Steps',
      checks: {
        reachable: {
          title: 'All pathpoints reachable',
          tip: 'Try moving the robot closer to the Path Points or try moving far away Path Points closer to the robot.'
        },
        kinematics: {
          title: 'Kinematics solvable',
          tip: 'Try getting help from one of our experts via the button below.'
        },
        loads: {
          title: 'Load & stress within part limits',
          tip: 'Try getting help from one of our experts via the button below.'
        }
      },
      kpis: {
        repetitions: 'Repetitions per hour',
        duration: 'Seconds per repetition'
      },
      kpisTitle: 'Task KPIs:',
      howToFix: 'How to fix:',
      checking: 'Checking feasibility..',
      feasible: 'This task is feasible!',
      problem: 'This task has a problem!',
      robotModulesTitle: 'Robot Modules'
    },
    dashboard: {
      title: 'Robot Tasks',
      create: 'Create New Task',
      teaching: 'Robot Teaching',
      lastEdited: 'last edited'
    },
    userStudy: {
      popup: {
        title: 'Welcome to this User Study! (~15 min)',
        section1: {
          text: 'This user study is part of a bachelor thesis on <strong>Task Specification for Modular Robots</strong> written at the robotics startup RobCo - The Robot Company. They have developed a <strong>modular robot arm that helps SMEs to automate manual tasks</strong> in their business.<br><br>This tool helps you figure out what robot will be best suited to automate your task.'
        },
        section3: {
          title: 'Your role in this User Study',
          text: [
            'You are the <strong>head of production</strong> at the Krüger Metallteile GmbH producing highly specialized custom metal parts with their CNC machines. Due to COVID-19 there has been more and more pressure to <strong>improve the efficiency</strong> of your production line. Especially <strong>taking parts in and out of the CNC</strong> machines (machine tending) <strong>is currently inefficient.</strong><br><br>You want to figure out <strong>if a RobCo robot is able to automate this task.</strong> To do this, you will now <strong>create a new task</strong>.',
            'To start, click on <strong>Start</strong> and then continue by clicking <strong>Create new Task</strong>.'
          ].join('<br>')
        },
        section2: {
          title: 'How to take part',
          text: [
            'This User Study will take approximately <strong>15 minutes</strong>. Please use a <strong>computer or laptop</strong> & either <strong>Firefox or Chrome</strong> as a browser. There are <strong>2 steps</strong>:',
            '1. <strong>Using this software</strong> tool, by creating a new task.',
            '2. <strong>Filling out a questionnaire</strong> afterwards (you will get the link later).'
          ].join('<br>')
        },
        start: 'Start'
      },
      donePopup: {
        title: 'You have completed the first step (75%)!',
        text: [
          'If you are happy with the task you created already, press <strong>Go to Questionnaire</strong>. You will be forwarded to a Google Form with some <strong>questions about your experience</strong>.',
          '',
          '<strong>But,</strong> feel free to instead <strong>keep improving your robot task</strong> until you are happy with it. The <strong>Go to Questionnaire</strong> button is also available <strong>in the Help Box (top left)</strong>.',
          '',
          'Thank you for making it this far!'
        ].join('<br>'),
        continue: 'Back to Editor'
      }
    },
    createTask: {
      selectType: {
        short: 'Select Task Type',
        title: 'What kind of task would you like to automate? (Application)',
        materialsHandling: {
          name: 'Materials Handling',
          description: 'Bin Picking'
        },
        machineTending: {
          name: 'Machine Tending',
          description: 'Adding / Removing parts and material'
        },
        qualityAssurance: {
          name: 'Quality Assurance',
          description: 'Performing Measurements'
        },
        dispensing: {
          name: 'Dispensing',
          description: 'Glueing'
        },
        finishing: {
          name: 'Finishing',
          description: 'Polishing'
        },
        materialRemoval: {
          name: 'Material Removal',
          description: 'Milling, Routing, etc.'
        },
        assembly: {
          name: 'Assembly',
          description: 'Part Insertion'
        },
        welding: {
          name: 'Welding',
          description: 'Soldering'
        }
      },
      nameTask: {
        short: 'Name New Task',
        title: 'Name your new robot task!',
        continue: 'Continue'
      },
      uploadEnvironmentScan: {
        short: 'Add Environment Scan',
        title: 'Add a scan of your environment!',
        defaultTitle: 'We have already uploaded a default file for you!',
        continue: 'Continue',
        defaultContinue: 'Continue with Default',
        selectFile: 'Select a .glb or .gltf File',
        importFile: 'URL to your .glb/.gltf file OR a poly.cam URL',
        dropFile: 'Drop a file here!',
        uploadFileTitle: 'Upload a file:',
        importFileTitle: 'Import a file from URL:',
        defaultFileTitle: 'Select a template:'
      }
    },
    labels: {
      preview: 'Preview Mode',
      editor: 'Editor',
      refineHotspot: 'Refine Path Point'
    },
    popups: {
      addHotspot: {
        title: 'Add new Path Point',
        placeholder: 'Path Point Description'
      }
    }
  }
}

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'de',
  messages
})

export default i18n
