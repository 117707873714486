



































import { Component, Prop, Vue } from 'vue-property-decorator'
import SideBar from '@/components/SideBar.vue'
import LanguageSwitch from '@/components/LanguageSwitch.vue'

@Component({
  components: {
    SideBar,
    LanguageSwitch
  }
})
export default class DashboardWrapper extends Vue {
  private minifiedSidebar = true;
  private name = process.env.VUE_APP_USER_NAME;
  private photoUrl = null;
}
