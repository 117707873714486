import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Teaching from '../views/Teaching.vue'
import Tasks from '../views/Tasks.vue'
import UserStudy from '../views/UserStudy.vue'
import SelectTaskType from '../views/SelectTaskType.vue'
import UploadEnvironment from '../views/UploadEnvironment.vue'
import NameTask from '../views/NameTask.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: process.env.VUE_APP_USER_STUDY === 'true' ? UserStudy : Tasks
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/teaching',
    name: 'teaching',
    component: Teaching
  },
  {
    path: '/tasks/:id',
    name: 'task',
    component: Home
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: Tasks
  },
  {
    path: '/new/type',
    name: 'type',
    component: SelectTaskType
  },
  {
    path: '/new/name',
    name: 'name',
    component: NameTask
  },
  {
    path: '/new/environment',
    name: 'environment',
    component: UploadEnvironment
  }
]

const router = new VueRouter({
  routes
})

export default router
