





import { Component, Vue } from 'vue-property-decorator'
import ModelViewerComponent from '@/components/ModelViewerComponent.vue'

@Component({
  components: {
    // GlTFConverter,
    // Stl2GlbConverter,
    ModelViewerComponent
  }
})

export default class Home extends Vue {}
