




























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class NumberInput extends Vue {
  @Prop({
    required: true
  }) private value!: number

  @Prop({
    default: ''
  }) private label!: string

  @Prop({
    default: ''
  }) private unit!: string

  @Prop({
    type: Number,
    default: 0.01
  }) private step!: number

  @Prop({
    type: String
  }) private icon: string | undefined

  private showUnit = true
}
