









import { Component, Prop, Vue } from 'vue-property-decorator'
import { Hotspot } from '@/models/Hotspot'

@Component
export default class HotspotLabelComponent extends Vue {
  @Prop() private hotspot!: Hotspot
  @Prop() private currentIndex!: number;

  onClick (): void {
    this.$emit('click', this.hotspot)
  }

  get actionType (): 'attach' | 'detach' {
    return this.hotspot.action?.Type || 'detach'
  }
}
