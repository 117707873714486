














































































import { Component, Vue } from 'vue-property-decorator'
import { Task } from '@/models/Task'
import { ApiAdapter } from '@/apiAdapter'
import DashboardWrapper from '@/components/DashboardWrapper.vue'

@Component({
  components: {
    DashboardWrapper
  }
})
export default class Tasks extends Vue {
  private tasks: Task[] = []
  private cardHeight = '35vh'
  private cardWidth = '25vh'

  private apiAdapter: ApiAdapter = new ApiAdapter()

  private mounted () {
    this.apiAdapter.listScenarios().then((res) => {
      this.tasks = res.data.map((s: any) => {
        return new Task(s.scenarioID, s.scenarioName, `${process.env.VUE_APP_BACKEND_URL}/previews/${s.scenarioID}.png`, new Date(Date.parse(s.date)))
      })
    })
  }

  private openTask (task: Task) {
    this.$router.push('/tasks/' + task.id)
  }

  private openTeaching () {
    this.$router.push('/teaching')
  }

  private openCreateTask () {
    this.$router.push('/new/type')
  }
}
