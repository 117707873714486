import * as THREE from 'three'

import { Action } from '@/models/Action'

export type ApproachMode = 'PointToPoint' | 'Linear' | 'Spline' | 'FollowSurface';

export class GeneratedHotspot {
  id!: string;
  position!: THREE.Vector3;
  quaternion!: THREE.Quaternion;
  approachMode!: ApproachMode;
  generated = true;

  constructor (id: string, position: THREE.Vector3 | string, orientation: THREE.Quaternion | THREE.Euler | THREE.Vector3 | string, approachMode: ApproachMode = 'Linear') {
    this.id = id
    if (typeof position === 'string') {
      this.dataPosition = position
    } else {
      this.position = position
    }

    if (orientation instanceof THREE.Quaternion) {
      this.quaternion = orientation
    } else if (orientation instanceof THREE.Euler) {
      this.quaternion = new THREE.Quaternion()
      this.rotation = orientation
    } else if (orientation instanceof THREE.Vector3) {
      this.quaternion = new THREE.Quaternion()
      this.normal = orientation
    } else {
      this.quaternion = new THREE.Quaternion()
      this.dataNormal = orientation
    }

    this.approachMode = approachMode
  }

  public get dataPosition (): string {
    return `${this.position.x} ${this.position.y} ${this.position.z}`
  }

  public set dataPosition (positionString: string) {
    this.position = this.parseVector3(positionString)
  }

  public get dataNormal (): string {
    return `${this.normal.x} ${this.normal.y} ${this.normal.z}`
  }

  public set dataNormal (normalString: string) {
    this.normal = this.parseVector3(normalString)
  }

  protected parseVector3 (input: string): THREE.Vector3 {
    const positionArray = input.split(' ')
    if (positionArray.length !== 3) throw new Error('Failed to parse THREE.Vector3 from input string: ' + input)

    const x = Number.parseFloat(positionArray[0])
    const y = Number.parseFloat(positionArray[1])
    const z = Number.parseFloat(positionArray[2])
    return new THREE.Vector3(x, y, z)
  }

  protected toJSON (): { [key: string]: any } {
    const hotspotWithGetters: { [key: string]: any } = {}
    for (const key in this) {
      hotspotWithGetters[key] = this[key]
    }
    hotspotWithGetters.dataPosition = this.dataPosition
    hotspotWithGetters.dataNormal = this.dataNormal
    return hotspotWithGetters
  }

  set rotation (euler: THREE.Euler) {
    this.quaternion.setFromEuler(euler)
  }

  get rotation (): THREE.Euler {
    return new THREE.Euler().setFromQuaternion(this.quaternion)
  }

  set normal (newNormalVector: THREE.Vector3) {
    const obj = new THREE.Object3D()
    obj.position.set(this.position.x, this.position.y, this.position.z)
    obj.lookAt(newNormalVector.add(obj.position))
    this.quaternion = obj.quaternion
  }

  get normal (): THREE.Vector3 {
    const normal = new THREE.Vector3()
    const obj = new THREE.Object3D()
    obj.setRotationFromQuaternion(this.quaternion)
    obj.getWorldDirection(normal)
    return normal
  }
}

export type InterpolationOptions = {
  intermediatePoints: number;
  castDistance: number;
  clearance: number;
  vectors?: GeneratedHotspot[];
}

export class Hotspot extends GeneratedHotspot {
  description!: string;
  selected!: boolean;
  interpolationOptions: InterpolationOptions;
  generated = false;
  action?: Action;

  constructor(id: string, position: THREE.Vector3, rotation: THREE.Quaternion, description: string, selected?: boolean, approachMode?: ApproachMode, interpolationOptions?: InterpolationOptions, action?: Action)
  constructor(id: string, position: string, rotation: THREE.Quaternion, description: string, selected?: boolean, approachMode?: ApproachMode, interpolationOptions?: InterpolationOptions, action?: Action)
  constructor(id: string, position: THREE.Vector3, rotation: THREE.Euler, description: string, selected?: boolean, approachMode?: ApproachMode, interpolationOptions?: InterpolationOptions, action?: Action)
  constructor(id: string, position: string, rotation: THREE.Euler, description: string, selected?: boolean, approachMode?: ApproachMode, interpolationOptions?: InterpolationOptions, action?: Action)
  constructor(id: string, position: THREE.Vector3, rotation: THREE.Vector3, description: string, selected?: boolean, approachMode?: ApproachMode, interpolationOptions?: InterpolationOptions, action?: Action)
  constructor(id: string, position: string, rotation: THREE.Vector3, description: string, selected?: boolean, approachMode?: ApproachMode, interpolationOptions?: InterpolationOptions, action?: Action)
  constructor(id: string, position: THREE.Vector3, rotation: string, description: string, selected?: boolean, approachMode?: ApproachMode, interpolationOptions?: InterpolationOptions, action?: Action)
  constructor(id: string, position: string, rotation: string, description: string, selected?: boolean, approachMode?: ApproachMode, interpolationOptions?: InterpolationOptions, action?: Action)
  constructor (id: string, position: THREE.Vector3 | string, rotation: THREE.Quaternion | THREE.Euler | THREE.Vector3 | string, description: string, selected = false, approachMode: ApproachMode = 'PointToPoint', interpolationOptions?: InterpolationOptions, action?: Action) {
    super(id, position, rotation, approachMode)

    this.description = description
    this.selected = selected
    this.approachMode = approachMode
    this.interpolationOptions = interpolationOptions ?? {
      intermediatePoints: 10,
      castDistance: 0.5,
      clearance: 0.001
    }
    this.action = action
  }
}
