
























































































import * as THREE from 'three'
import { Component, Prop, Vue } from 'vue-property-decorator'
import NumberInput from '@/components/NumberInput.vue'

@Component({
  components: {
    NumberInput
  }
})
export default class EditHotspotMenu extends Vue {
  @Prop() private position!: THREE.Vector3
  @Prop() private rotation!: THREE.Euler
  @Prop() private setRefineRobot!: (r: boolean) => void
  @Prop({
    default: false
  }) readonly refineRobot!: boolean

  @Prop() private setTransformControlsMode!: (val: 'translate' | 'rotate') => void
  @Prop() private transformControlsMode!: 'translate' | 'rotate'

  private changeControlMode (): void {
    if (this.transformControlsMode === 'rotate') {
      this.setTransformControlsMode('translate')
    } else {
      this.setTransformControlsMode('rotate')
    }
  }

  private refineRobotButton (): void {
    if (!this.refineRobot) {
      this.setRefineRobot(true)
    } else {
      this.setRefineRobot(false)
    }
  }

  set rotationX (x: string) {
    const xRad = parseFloat(x) * Math.PI / 180
    if (isNaN(xRad)) return
    this.rotation.set(xRad, this.rotation.y, this.rotation.z)
  }

  get rotationX (): string {
    const xDeg = this.rotation.x / Math.PI * 180
    return xDeg.toString()
  }

  set rotationY (y: string) {
    const yRad = parseFloat(y) * Math.PI / 180
    if (isNaN(yRad)) return
    this.rotation.set(this.rotation.x, yRad, this.rotation.z)
  }

  get rotationY (): string {
    const yDeg = this.rotation.y / Math.PI * 180
    return yDeg.toString()
  }

  set rotationZ (z: string) {
    const zRad = parseFloat(z) * Math.PI / 180
    if (isNaN(zRad)) return
    this.rotation.set(this.rotation.x, this.rotation.y, zRad)
  }

  get rotationZ (): string {
    const zDeg = this.rotation.z / Math.PI * 180
    return zDeg.toString()
  }

  set positionX (x: string) {
    const xPos = parseFloat(x)
    if (isNaN(xPos)) return
    this.position.setX(xPos)
  }

  get positionX (): string {
    return this.position.x.toString()
  }

  set positionY (y: string) {
    const yPos = parseFloat(y)
    if (isNaN(yPos)) return
    this.position.setY(yPos)
  }

  get positionY (): string {
    return this.position.y.toString()
  }

  set positionZ (z: string) {
    const zPos = parseFloat(z)
    if (isNaN(zPos)) return
    this.position.setZ(zPos)
  }

  get positionZ (): string {
    return this.position.z.toString()
  }
}
