import * as THREE from 'three'
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js'
import { Vue } from 'vue-property-decorator'

import RobotLabel from '@/components/RobotLabel.vue'

export class RobotMarker extends THREE.Group {
  private label: CSS2DObject;

  constructor (position: THREE.Vector3 | null, quaternion: THREE.Quaternion | null, onClick: (e: string | string[]) => void) {
    super()
    const baseHeight = 0.1
    const baseGeo = new THREE.BoxGeometry(0.16, baseHeight, 0.16)
    const baseMaterial = new THREE.MeshStandardMaterial({
      opacity: 0.6,
      transparent: true,
      color: 'blue'
    })
    const base = new THREE.Mesh(baseGeo, baseMaterial)
    base.translateY(baseHeight / 2)
    this.add(base)

    const cylinderLength = 0.1
    const cylinderGeo = new THREE.CylinderGeometry(0.04, 0.04, cylinderLength)
    const cylinderMaterial = new THREE.MeshStandardMaterial({
      opacity: 0.6,
      transparent: true,
      color: 'blue'
    })
    const cylinder = new THREE.Mesh(cylinderGeo, cylinderMaterial)
    cylinder.translateY(baseHeight * 1.5)
    this.add(cylinder)

    const RobotLabelClass = Vue.extend(RobotLabel)
    const robotLabelInstance = new RobotLabelClass({})
    robotLabelInstance.$on('click', onClick)
    robotLabelInstance.$mount()
    const robotElement = robotLabelInstance.$el as HTMLElement
    this.label = new CSS2DObject(robotElement)
    this.add(this.label)
    this.visible = position !== null && quaternion !== null
    this.label.visible = position !== null && quaternion !== null

    if (position) this.position.copy(position)
    if (quaternion) this.quaternion.copy(quaternion)
  }

  public dispose (): void {
    this.traverse((c: THREE.Object3D) => {
      if (c instanceof THREE.Mesh) {
        c.geometry.dispose()
        c.material.dispose()
      }
    })
  }

  public setLabelVisible (val: boolean): void {
    this.label.visible = val
  }
}
