


































































import { Component, Vue } from 'vue-property-decorator'
import { Task } from '@/models/Task'
import { ApiAdapter } from '@/apiAdapter'
import SideBar from '@/components/SideBar.vue'
import LanguageSwitch from '@/components/LanguageSwitch.vue'
import DashboardWrapper from '@/components/DashboardWrapper.vue'

import UserStudyPopup from '@/components/UserStudyPopup.vue'

@Component({
  components: {
    DashboardWrapper,
    UserStudyPopup,
    SideBar,
    LanguageSwitch
  }
})
export default class UserStudy extends Vue {
  private tasks: Task[] = []
  private cardHeight = '35vh'
  private cardWidth = '25vh'
  private popup = true

  private apiAdapter: ApiAdapter = new ApiAdapter()

  private mounted () {
    this.apiAdapter.listScenarios().then((res) => {
      const taskId = localStorage.getItem('task')
      this.tasks = res.data.filter((s: any) => s.scenarioID === taskId).map((s: any) => {
        return new Task(s.scenarioID, s.scenarioName, `${process.env.VUE_APP_BACKEND_URL}/previews/${s.scenarioID}.png`, new Date(Date.parse(s.date)))
      })
    })
  }

  private openTask (task: Task) {
    this.$router.push('/tasks/' + task.id)
  }

  private openTeaching () {
    this.$router.push('/teaching')
  }

  private openCreateTask () {
    this.$router.push('/new/type')
  }

  private setPopup (val: boolean): void {
    this.$gtag.customMap({
      dimension1: 'task',
      metric1: 'timestamp'
    })
    this.$gtag.event('start', {
      event_category: 'UserStudy',
      value: Math.round(Date.now() / 1000),
      timestamp: Math.round(Date.now() / 1000)
    })
    if (typeof val !== 'boolean') {
      this.popup = !this.popup
    } else {
      this.popup = val
    }
  }
}
