import * as THREE from 'three'

export class ToolMarker extends THREE.Mesh {
  constructor () {
    const material = new THREE.MeshBasicMaterial({ color: 0xffff00 })
    const coneLength = 0.1
    const coneGeo = new THREE.ConeGeometry(0.02, coneLength)
    const cone = new THREE.Mesh(coneGeo, material)
    const sphereGeo = new THREE.SphereGeometry(0.02, 32, 32)
    super(sphereGeo, material)

    this.name = 'animatedToolMarker'
    this.add(cone)
    cone.position.add(new THREE.Vector3(0, 0, 1).multiplyScalar(-coneLength / 2))
    cone.rotateOnAxis(new THREE.Vector3(1, 0, 0), Math.PI / 2)
  }
}
