import * as THREE from 'three'
import { Line2 } from 'three/examples/jsm/lines/Line2.js'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js'

export class ThickLine extends Line2 {
  constructor (canvasDiv: HTMLElement, points: THREE.Vector3[], width = 1, color?: number, dashed = false) {
    const geometry = new LineGeometry()

    geometry.setPositions(points.reduceRight((prev: number[], curr) => {
      prev.push(curr.x)
      prev.push(curr.y)
      prev.push(curr.z)
      return prev
    }, []))

    color = color || 0x252644
    const matLine = new LineMaterial({
      color,
      linewidth: width, // in pixels
      dashed: dashed,
      dashSize: 0.05,
      gapSize: 0.05
    })

    matLine.resolution.set(canvasDiv.clientWidth, canvasDiv.clientHeight)

    super(geometry, matLine)
    this.computeLineDistances()
    this.scale.set(1, 1, 1)
  }

  public dispose (): void {
    this.material.dispose()
    this.geometry.dispose()
  }
}
