































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class Stepper extends Vue {
  @Prop(Number) private step!: number
}
